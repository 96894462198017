import { css } from "@emotion/react";

export function getStyles() {
  return {
    global: css`
      body {
        background-color: #ccc;
      }
     `,
    container: css`
      display: flex;
      padding: 20px;
    `,
    rightContainer: css`
      max-width: 350px;
    `,
    /*
    right: css`
      flex-basis: 1;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
    `,
    */
    dashboard: css`
      flex-basis: 2;
    `,
    links: css`
      padding: 20px;
      a {
        padding: 6px 12px;
        margin-right: 10px;
        color: #737373;
        text-decoration: none;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-size: 12px;
        border-radius: 24px;
        background-color: white;
        &.active {
          //font-weight: 600;
          color: white;
          background-color: #737373;
        }
      }
    `,
    selector: css`
      margin-left: 10px;
      & .MuiChip-root {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    `,
    account: css`
      font-size: 11px;
      color: #999;
      &.active {
        color: green;
      }
    `,
    dateRange: css`
      display: flex;
      margin-left: 10px;
      align-items: center;
    `,
    datePicker: css`
      display: flex;
      align-items: center;
      width: 110px;
      &:last-child {
        margin-left: 20px;
      }
      & .MuiTextField-root {
        border-bottom: none;
      }
      & svg {
        font-size: 1.15rem;
        margin-bottom: 3px;
      }
      & .MuiInputBase-root {
        font-size: 0.85rem;
      }
      & input {
        padding: 0;
        padding-bottom: 3px;
      }
    `,
  }
}

