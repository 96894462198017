import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin, IFarm, ILiquidityPair, IToken } from "../types";
import SX from "../abi/SX.json";
import USDC from "../abi/USDC.json";
import WBTC from "../abi/WBTC.json";
import WETH from "../abi/WETH.json";
// import UniswapV2Pair from "../abi/UniswapV2Pair.json";
import UniswapV3Pool from "../abi/UniswapV3Pool.json";
import SUSHI from "../abi/SUSHI.json";
import XSUSHI from "../abi/XSUSHI.json";
import OHM from "../abi/OHM.json";
import MinTokenInfo from "../abi/MinTokenInfo.json";
// import MinFarmInfo from "../abi/MinFarmInfo.json";
// import MiniChef from "../abi/MiniChef.json";

const tokens: IToken[] = [
  {
    id: `${AssetId.ETH}:${Chain.MAINNET}`,
    assetId: AssetId.ETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.NATIVE,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.MAINNET,
    decimals: 18,
  },
  {
    id: `${AssetId.WETH}:${Chain.MAINNET}`,
    assetId: AssetId.WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.MAINNET,
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    decimals: 18,
    abi: WETH
  },
  {
    id: `${AssetId.DAI}:${Chain.MAINNET}`,
    assetId: AssetId.DAI,
    coin: Coin.DAI,
    groupId: AssetGroupId.DAI,
    category: AssetCategory.STABLE_COIN,
    type: AssetType.TOKEN,
    chainId: Chain.MAINNET,
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.USDC}:${Chain.MAINNET}`,
    assetId: AssetId.USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.MAINNET,
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    decimals: 6,
    abi: USDC
  },
  {
    id: `${AssetId.WBTC}:${Chain.MAINNET}`,
    assetId: AssetId.WBTC,
    coin: Coin.BTC,
    groupId: AssetGroupId.BTC,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.MAINNET,
    address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    decimals: 8,
    abi: WBTC
  },
  {
    id: `${AssetId.SUSHI}:${Chain.MAINNET}`,
    assetId: AssetId.SUSHI,
    coin: Coin.SUSHI,
    groupId: AssetGroupId.SUSHI,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
    decimals: 18,
    abi: SUSHI
  },
  {
    id: `${AssetId.XSUSHI}:${Chain.MAINNET}`,
    assetId: AssetId.XSUSHI,
    coin: Coin.XSUSHI,
    groupId: AssetGroupId.STAKING_TOKENS,
    type: AssetType.STAKING,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272",
    decimals: 18,
    abi: XSUSHI
  },
  {
    id: `${AssetId.SX}:${Chain.MAINNET}`,
    assetId: AssetId.SX,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0xbe9f61555f50dd6167f2772e9cf7519790d96624",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SX_DEPRECATED}:${Chain.MAINNET}`,
    assetId: AssetId.SX_DEPRECATED,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.DEPRECATED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0x99fE3B1391503A1bC1788051347A1324bff41452",
    decimals: 18,
    abi: SX
  },
  {
    id: `${AssetId.OHM}:${Chain.MAINNET}`,
    assetId: AssetId.OHM,
    coin: Coin.OHM,
    groupId: AssetGroupId.OHM,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0x383518188C0C6d7730D91b2c03a03C837814a899",
    decimals: 9,
    abi: OHM,
    inactive: true,
  },
  {
    id: `${AssetId.SOHM}:${Chain.MAINNET}`,
    assetId: AssetId.SOHM,
    coin: Coin.OHM,
    groupId: AssetGroupId.OHM,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0x04f2694c8fcee23e8fd0dfea1d4f5bb8c352111f",
    decimals: 9,
    abi: OHM,
    inactive: true,
  },
  {
    id: `${AssetId.DYDX}:${Chain.MAINNET}`,
    assetId: AssetId.DYDX,
    coin: Coin.DYDX,
    groupId: AssetGroupId.DYDX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.MAINNET,
    address: "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
    decimals: 18,
    abi: MinTokenInfo,
    inactive: true,
  },
]

const lps: ILiquidityPair[] = [
  /*
  {
    id: `${AssetId.WETH_SX_ULP}:${Chain.MAINNET}`,
    assetId: AssetId.WETH_SX_ULP,
    coin: Coin.WETH_SX_ULP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.MAINNET,
    address: "0xD43489Eb11E9C0cfe5d69C3ae6AC7f9c8ca86A00",
    decimals: 18,
    abi: UniswapV3Pool,
    relatedAssetIds: [AssetId.SX, AssetId.WETH],
  },
  */

  /*
  {
    id: `${AssetId.OHM_WETH_SLP}:${Chain.MAINNET}`,
    assetId: AssetId.OHM_WETH_SLP,
    coin: Coin.OHM_WETH_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.MAINNET,
    address: "0xfffAE4A0F4AC251F4705717cD24CaDcCc9f33E06",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.OHM, AssetId.WETH],
  },
  {
    id: `${AssetId.DYDX_WETH_SLP}:${Chain.MAINNET}`,
    assetId: AssetId.DYDX_WETH_SLP,
    coin: Coin.DYDX_WETH_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.MAINNET,
    address: "0xe4ebd836832f1a8a81641111a5b081a2f90b9430",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.DYDX, AssetId.WETH],
  },
  */
]

const farms: IFarm[] = [
  /*
  {
    id: `${AssetId.DYDX_WETH_SLP_STAKED}:${Chain.MAINNET}`,
    assetId: AssetId.DYDX_WETH_SLP_STAKED,
    coin: Coin.DYDX_WETH_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.MAINNET,
    address: "0xEF0881eC094552b2e128Cf945EF17a6752B4Ec5d",
    decimals: 18,
    // abi: MinFarmInfo,
    abi: MiniChef,
    pid: 18,
    relatedAssetIds: [AssetId.DYDX_WETH_SLP],
  },
  */
]


export { tokens, lps, farms }
