import { AssetId, Chain, Coin, Fiat, IRatePair, RateType, Preset } from "../types"


const ratePairs: IRatePair[] = [

  {
    id: "USDC_SHARK_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.USDC_SHARK_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.USDC_SHARK_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "USDC_WETH_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.USDC_WETH_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.USDC_WETH_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "WSX_WETH_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WSX_WETH_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WSX_WETH_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "WSX_USDC_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WSX_USDC_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WSX_USDC_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "WSX_SHARK_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WSX_SHARK_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WSX_SHARK_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "USDC_WMATIC_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.USDC_WMATIC_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.USDC_WMATIC_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "WMATIC_WETH_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WMATIC_WETH_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WMATIC_WETH_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "WSX_WMATIC_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WSX_WMATIC_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WSX_WMATIC_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },
  {
    id: "SHARK_WETH_TLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SHARK_WETH_TLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SHARK_WETH_TLP}:${Chain.TORONTO}`,
    presets: [Preset.TORONTO]
  },

]

export default ratePairs

