import { Box, Stack } from "@mui/material"
//import { ByCategory } from "../charts/ByCategory"
//import { ByGroup } from "../charts/ByGroup"
import Pairs from "../liquidityPairs/Pairs"
import Rates from "../rates/Rates"
import Balances from "./Balances"
import Charts from "../charts/Charts"
import Quotes from "../quotes"

function Dashboard() {
  return (
    <Box>
      <Stack direction="row" spacing={2} padding={2}>
        <Balances />
        <Stack direction="column" spacing={2}>
          <Rates />
          <Charts />
          <Pairs />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Dashboard

/*
          <Quotes />
*/
