import { Preset } from "./types";
import { WalletIds } from "./wallets";

export interface IAccount {
  address: string;
  label: string;
  walletId: string;
  auth?: boolean;
  presets: Preset[];
}

export const Accounts: IAccount[] = [
  {
    address: "0x6aECa9A564C210BAb9D40bD6C8A41683d8D9cDBa",
    label: "Holdings",
    walletId: WalletIds.CHROME_MM,
    auth: true,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x89181a0E0a9de0B5B21033e5b5A1dEBFcf097065",
    label: "Account 2",
    walletId: WalletIds.CHROME_MM,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.SXN]
  },
  {
    address: "0x37A09fA7aFBbb48537bf94d5609d3E2Fe04fA791",
    label: "Account 3",
    walletId: WalletIds.CHROME_MM,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x6a0f764ab795A48Bcdc9068a52A1969aFFb328A5",
    label: "Account 4 ktourn",
    walletId: WalletIds.CHROME_MM,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x7BCcaA08725dCF8B4bf616c3de686aab3139DA7d",
    label: "Trading 1 sbridge",
    walletId: WalletIds.BRAVE_MM,
    auth: true,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x7DcaF5e47EFd32835c394B5C8095a845D6C3B6b2",
    label: "Trading 2 uriaheep",
    walletId: WalletIds.BRAVE_MM,
    auth: true,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x47378BFb91FcdD9a377D44C13f7Cff185129b6d5",
    label: "Holdings",
    walletId: WalletIds.BRAVE_MM,
    auth: true,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.SXN]
  },
  {
    address: "0xEd1a449E84E3DdEc2a31fE345f5Ee5A0f4020dc6",
    label: "Extra",
    walletId: WalletIds.BRAVE_MM,
    auth: true,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.SXN]
  },
  {
    address: "0x5Dca685B9368844198baE19Cc5AdDddcB3592f64",
    label: "Vydoplyasov",
    walletId: WalletIds.FIREFOX_MM,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0xAaD326f305E76378158dC824199056Dd93f64868",
    label: "Trading finsider",
    walletId: WalletIds.FIREFOX_MM,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x089c0C219A373449e476D7b762450996bbCf4624",
    label: "Account 3",
    walletId: WalletIds.FIREFOX_MM,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x093776982c0F6Ce635312EDa63048A4A9ED56A2D",
    label: "Account 1",
    walletId: WalletIds.IPHONE_MM,
    auth: true,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0xd3B458659d0960788C704802080BB63E14e193bc",
    label: "Account 2",
    walletId: WalletIds.IPHONE_MM,
    auth: true,
    presets: [Preset.TORONTO]
  },
  {
    address: "0x51D4B51F9251912c85Af18AF6e526eCB976ad810",
    label: "Fortmatic icloud",
    walletId: WalletIds.ICLOUD_FM,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  {
    address: "0x70685060Aca5973B882D041497ef51156d2287Ad",
    label: "Fortmatic nextgen",
    walletId: WalletIds.NEXTGEN_FM,
    presets: [Preset.NEXT_GEN]
  },
  {
    address: "0xf0d6dE16e2c14e0598A18C2B43716d7D0672690c",
    label: "Nextgen test",
    walletId: WalletIds.NEXTGEN_TEST,
    presets: [Preset.NEXT_GEN]
  },
  {
    address: "0x1A16250c062D7076b6E33d62890616153F728789",
    label: "Sxn 1",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0xD88445388920d89216F1663F0Ee40a29A571E0dd",
    label: "Sxn 2",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0xa41213D27F7efa45654421972e044767Bd082682",
    label: "Sxn 3",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0x5D6ae99C7caA0004F9e8a78eC61683213cb2f4a4",
    label: "Sxn 4",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0x32C68DcFB218Fb7956b8636C039cD37a8E16CCb2",
    label: "Sxn 5",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  /*
  {
    address: "0xde68DE502066996622Dd8e09b39eb2ef2324D067",
    label: "Sxn 6",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0x7E3459ff835015511BA79242d4F3593A6C8Febb2",
    label: "Sxn 7",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0x9eC24B57a65C618c76Df97b9a5A08A5Dd777F418",
    label: "Sxn 8",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0x1eE4E367a9CB6Ec153C7486076A9215CAb4aEA1E",
    label: "Sxn 9",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  {
    address: "0xa19D889bDbcd45898Ef814E5Fb0df4D529333350",
    label: "Sxn 10",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  */
  {
    address: "0xEbB9EE54D6B5E115f0411e90826D1e052a382a2d",
    label: "Val",
    walletId: WalletIds.PAPER,
    presets: [Preset.PERSONAL]
  },
  {
    address: "0xc829930ADA40F27515f2f986167bF9f12f4E2c35",
    label: "Del 1",
    walletId: WalletIds.PAPER,
    presets: [Preset.PERSONAL]
  },
  {
    address: "0x786EfA9Fa71f6a9D29793a37c4A63F34a93d75f2",
    label: "Del 2",
    walletId: WalletIds.PAPER,
    presets: [Preset.PERSONAL]
  },
  {
    address: "0x8F374b00345Ae0EbaA27e34A7354D2F9fA2DE27D",
    label: "Del 3",
    walletId: WalletIds.PAPER,
    presets: [Preset.PERSONAL]
  },
  {
    address: "0x88d6672B429557d8b88341B7fb237429fA826734",
    label: "Stor 1",
    walletId: WalletIds.PAPER,
    presets: [Preset.LOCKED]
  },
  {
    address: "0xa6a9486f5dFf3f4444773F203De9aa634B46d1eA",
    label: "Stor 2",
    walletId: WalletIds.PAPER,
    presets: [Preset.LOCKED]
  },
  {
    address: "0x1713B9067c4fC8BEd9d20A5f2F0635f2B0Bf2dfD",
    label: "ethereum 1",
    walletId: WalletIds.NANO_ETH,
    presets: [Preset.PERSONAL, Preset.STORAGE]
  },
  {
    address: "0x512498e26d83224544eF3F789ffA0EFb0b805606",
    label: "ethereum 2",
    walletId: WalletIds.NANO_ETH,
    presets: [Preset.PERSONAL, Preset.STORAGE]
  },
  {
    address: "bc1qaxvmxzlwzkl6utmakxrj7su0u6c04r4ks2cnwm",
    label: "bitcoin 1",
    walletId: WalletIds.NANO_BTC,
    presets: [Preset.PERSONAL, Preset.STORAGE]
  },
  {
    address: "bc1q8we89zhrjw4wfm7dems5jpgvtpekk2s3kzy7y5",
    label: "bitcoin 2",
    walletId: WalletIds.NANO_BTC,
    presets: [Preset.PERSONAL, Preset.STORAGE]
  },
  {
    address: "0xfE8601Dcd1eC8bE17D299EaFEcAe68cF1Aa89075",
    label: "NFTs 1",
    walletId: WalletIds.NFTS_LIZA,
    presets: [Preset.UNUSED]
  },
  {
    address: "0xD8e4Ec5Eb78eb627d1f8d5364cc8966a29D2E148",
    label: "NFTs 2",
    walletId: WalletIds.NFTS_LIZA,
    presets: [Preset.UNUSED]
  },


  {
    address: "0x7177B88A894EA0474B344F7B73aB6AAe40158629",
    label: "Toronto 1",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x2079A994E9BBc88496Df03A59A0CFA2C9E2af473",
    label: "Toronto 2",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x832A9a4d5e255b5ECe1dc6775e17014B454450af",
    label: "Toronto 3",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0xf3571cCC5579e5763AE86A8600360364deD2F46b",
    label: "Toronto 4",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0xFf6dfFD5D1B834082B27DB43C4BB8D8023a565dc",
    label: "Toronto 5",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0xbd9E0e01E1D836dF04a568e9623C9F490914542D",
    label: "Toronto 6",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x78B7BC9FdC7Dc431d385d1E18cAC6B53dDCf4ba9",
    label: "Toronto 7",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x9F2BA335Ccb01312600075ad305961405B800205",
    label: "Toronto 8",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x8f495cBC687396DEB6137fFb9CC4119E8591DEFB",
    label: "Toronto 9",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x1880c02e6067f50AebC0DdD8bEa64dad8eEE2d9e",
    label: "Toronto 10",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0x9d982fD52640042D47b3d863555b7AC67B785b5e",
    label: "Fortmatic NextGen",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  {
    address: "0xAe7660966fD4fB38eB9Bc4cd6e1916c8eE72FC50",
    label: "Validator test 1",
    walletId: WalletIds.TORONTO_TEST,
    presets: [Preset.TORONTO],
  },
  /*
  {
    address: "0x6a0f764ab795A48Bcdc9068a52A1969aFFb328A5",
    label: "Konstantin",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {

    address: "0x620785a8933fDd957669b3983334f24c2Cca49e8",
    label: "Jeremy",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xA0Dda95EDe0Ea21E8713D8C663C6be1e15F7eAfF",
    label: "Akshar",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0x8d4A5ca618AAfB47afDc8b8004b9Ce709d290489",
    label: "Mike",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xB7d8e2E48Bf6Bfe29746514af94A37176E015d46",
    label: "AY",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xD0CB86226298c1719565a6Df43C4ff8A98b604a7",
    label: "Kay",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xc204d9831002c949b16AA78E7474cA28C4AD2163",
    label: "Julian",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xa6fa134f76496300419E6dbee487239F09d247aE",
    label: "Declan",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xE2a8C77977Ed0903993168FB39C6f8e0484C85CE",
    label: "Artem",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xF8A08149C18F8bcFD3e1f4C07BB2F78b25207d05",
    label: "Lakshan",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xeCD7AE99a36eF0D9799fD16757db1C7cad716734",
    label: "Dan",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0x77b9E683AB130BDBb56BBE6F6F294831BE72955E",
    label: "Jake",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  {
    address: "0xca48a525f465A0eA44CFDdDca23a61E34B37c52f",
    label: "Jay",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.TOURNAMENT],
  },
  */

];

/*

  {
    address: "0xfE8601Dcd1eC8bE17D299EaFEcAe68cF1Aa89075",
    label: "NFTs 1",
    walletId: WalletIds.NFTS_LIZA,
    presets: [Preset.STORAGE]
  },
  {
    address: "0xD8e4Ec5Eb78eb627d1f8d5364cc8966a29D2E148",
    label: "NFTs 2",
    walletId: WalletIds.NFTS_LIZA,
    presets: [Preset.STORAGE]
  },

*/

  /*
  {
    address: "0xcB418932376f4c9B12bA2078A7b4cf85748444bb",
    label: "Locked Holdings",
    walletId: WalletIds.LOCKED_WALLET,
    presets: [Preset.PERSONAL, Preset.MCC]
  },
  */

  /*
  {
    address: "0xfF9D9877F5Ad7E556C6C9f781F9295A2dF0b99a3",
    label: "Ray's account",
    walletId: WalletIds.NEXTGEN_ADMIN,
    presets: [Preset.NEXT_GEN],
  },
  */
  
