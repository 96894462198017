import useSWR from "swr"
import { ethers } from "ethers"
import { toChecksumAddress } from "ethereum-checksum-address"
import { subgraphEndpoints } from "../../config"
import { Chain, Exchange, SubgraphType } from "../../config/types"
import { IScope } from "../../state/app"
import { ISwapTxState } from "./types"
import { Tokens } from "../../config/assets"
import { isEthereumAddress } from "../../utils"
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "."
import { fetcherWithRates } from "./shared"

const query = /* GraphQL */ `
  query ($accounts: [String!]!, $startDate: Int, $endDate: Int, $first: Int, $skip: Int) {
    swaps(
      first: $first
      skip: $skip
      orderBy: timestamp 
      orderDirection: desc
      where: { 
        initiator_in: $accounts
        timestamp_gte: $startDate
        timestamp_lte: $endDate 
      }
    ) {
      id
      # augustus
      # augustusVersion
      side
      # method
      initiator
      beneficiary
      srcToken
      destToken
      srcAmount
      destAmount
      expectedAmount
      # referrer
      txHash
      txOrigin
      txTarget
      txGasUsed
      txGasPrice
      blockHash
      blockNumber
      timestamp
    }
  }
`

export function useParaswapSwapTxs(
  chainId: Chain,
  exchange: Exchange,
  scope: IScope,
) {
  const toFetch = scope.chains.includes(chainId) && scope.exchanges.includes(exchange)
  const endpoint = subgraphEndpoints[chainId][exchange][SubgraphType.PARASWAP]
  const variables = {
    accounts: scope.accounts.filter(e => isEthereumAddress(e)),
    startDate: scope.startDate || DEFAULT_START_DATE,
    endDate: scope.endDate || DEFAULT_END_DATE,
  }

  const config = {
  }

  const { data, error } = useSWR(toFetch ? [endpoint, query, variables] : null, fetcherWithRates, config)

  // console.log("-------- paraswap", data, error)

  const { utils } = ethers
  let swaps: ISwapTxState[] = []

  if (!endpoint) return { data: swaps }
  if (error) return { error }
  if (!data && !error) return { loading: true }

  for (let tx of data!.swaps) {
    const timestamp = Number(tx.timestamp)
    const block = Number(tx.blockNumber)
    const account = toChecksumAddress(tx.initiator)
    const txHash = tx.txHash
    const addressIn = toChecksumAddress(tx.srcToken)
    const addressOut = toChecksumAddress(tx.destToken)
    const tokenIn = Tokens.find(e => e.address === addressIn && e.chainId === chainId)!
    const tokenOut = Tokens.find(e => e.address === addressOut && e.chainId === chainId)!
    if (!tokenIn || !tokenOut) {
      console.log("-------- missing tokens", tx.srcToken, tx.destToken, addressIn, addressOut)
    }
    const symbolIn = tokenIn.assetId
    const symbolOut = tokenOut.assetId
    const coinIn = tokenIn.coin
    const coinOut = tokenOut.coin
    const amountIn = Number(utils.formatUnits(tx.srcAmount, tokenIn.decimals))
    const amountOut = Number(utils.formatUnits(tx.destAmount, tokenOut.decimals))
    const amountUSD = amountOut * tx.destTokenRate

    // const { data: rateData } = useTokenRate(block, tokenOut.address)

    swaps.push({
      chainId,
      exchange,
      timestamp,
      block,
      account,
      txHash,
      addressIn,
      addressOut,
      symbolIn,
      symbolOut,
      coinIn,
      coinOut,
      amountIn,
      amountOut,
      amountUSD,
    })
  }


  return { data: swaps }
}

