import { Coin, Fiat, IRatePair, RateType, Preset } from "../types";
import SXN from "./sxn"
import Polygon from "./polygon"
import Mainnet from "./mainnet"
import Arbitrum from "./arbitrum"
import Toronto from "./toronto"

const ratePairs: IRatePair[] = [
  {
    id: "DAI:USD",
    type: RateType.FIXED,
    query: Coin.DAI,
    base: Fiat.USD,
    fixedRate: 1,
    presets: []
  },
  {
    id: "USDC:USD",
    type: RateType.FIXED,
    query: Coin.USDC,
    base: Fiat.USD,
    fixedRate: 1,
    presets: []
  },
  {
    id: "ARB:USD",
    type: RateType.INDIRECT,
    query: Coin.ARB,
    base: Fiat.USD,
    path: ["ARB:ETH:ARBITRUM", "ETH:USD"],
    presets: [Preset.PERSONAL, Preset.NEXT_GEN]
  },
  {
    id: "DEPRECATED:USD",
    type: RateType.FIXED,
    query: Coin.DEPRECATED,
    base: Fiat.USD,
    fixedRate: 0,
    presets: []
  },
  {
    id: "BTC:USD",
    type: RateType.INDIRECT,
    query: Coin.BTC,
    base: Fiat.USD,
    path: ["BTC:ETH", "ETH:USD"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.SXN]
  },
  {
    id: "BTC:SX",
    type: RateType.INDIRECT,
    query: Coin.BTC,
    base: Coin.SX,
    path: ["BTC:ETH", "ETH:SX"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.SXN]
  },
  {
    id: "SX:USD",
    type: RateType.INDIRECT,
    query: Coin.SX,
    base: Fiat.USD,
    path: ["ETH:SX", "ETH:USD"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN, Preset.TORONTO]
  },
  {
    id: "SUSHI:USD",
    type: RateType.INDIRECT,
    query: Coin.SUSHI,
    base: Fiat.USD,
    path: ["SUSHI:ETH", "ETH:USD"],
    presets: [Preset.NEXT_GEN]
  },
  {
    id: "XSUSHI:USD",
    type: RateType.INDIRECT,
    query: Coin.XSUSHI,
    base: Fiat.USD,
    path: ["XSUSHI:ETH", "ETH:USD"],
    presets: [Preset.NEXT_GEN]
  },

]

const all = [
  ...ratePairs, ...Polygon, ...Mainnet, ...Arbitrum, ...SXN, ...Toronto
]

export default all

  /*
  {
    id: "IDEX:USD",
    query: Coin.IDEX,
    base: Fiat.USD,
    token1AsBase: true,
    chainId: Chain.POLYGON,
    platformId: Exchange.IDEX,
    address: "0xfcD9e1624938d8C8dA568DD6C75E3396Ee762301", // not a uniswap pair!
    abi: UniswapV2Pair,
    token0: AssetId.IDEX,
    token1: AssetId.USDC,
    presets: [Scope.STORAGE]
  },
  */

  /*
  {
    id: "DINO:USD",
    query: Coin.DINO,
    base: Fiat.USD,
    chainId: Chain.POLYGON,
    platformId: Exchange.SUSHISWAP,
    address: "0x3324af8417844e70b81555A6D1568d78f4D4Bf1f",
    abi: UniswapV2Pair,
    token0: AssetId.USDC,
    token1: AssetId.DINO,
    presets: []
  },
  {
    id: "CRYSTL:MATIC",
    query: Coin.CRYSTL,
    base: Coin.MATIC,
    chainId: Chain.POLYGON,
    platformId: Exchange.APESWAP,
    address: "0xb8e54c9ea1616beebe11505a419dd8df1000e02a",
    abi: UniswapV2Pair,
    token0: AssetId.WMATIC,
    token1: AssetId.CRYSTL,
    presets: []
  },
  {
    id: "CRYSTL:USD",
    query: Coin.CRYSTL,
    base: Fiat.USD,
    path: ["CRYSTL:MATIC", "MATIC:USD"],
    presets: []
  },
  */

  /*  
  {
    id: "WMATIC_SX_ALP:USD",
    query: Coin.WMATIC_SX_ALP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WMATIC_SX_ALP}:${Chain.POLYGON}`,
    path0: ["MATIC:USD"],
    path1: ["ETH:SX", "ETH:USD"],
    presets: []
  },
  {
    id: "WETH_DAI_SLP:USD",
    query: Coin.WETH_DAI_SLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WETH_DAI_SLP}:${Chain.POLYGON}`,
    path0: ["ETH:USD"],
    path1: ["DAI:USD"],
    presets: []
  },

  {
    id: "SX_USDC_ILP:USD",
    type: RateType.COMPLEX,
    query: Coin.SX_USDC_ILP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SX_USDC_ILP}:${Chain.POLYGON}`,
    presets: [Scope.STORAGE]
  },
  */

  /*
  {
    id: "OHM_WETH_SLP:USD",
    type: RateType.COMPLEX,
    query: Coin.OHM_WETH_SLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.OHM_WETH_SLP}:${Chain.MAINNET}`,
    //path0: ["OHM:USD"],
    //path1: ["ETH:USD"],
    presets: [Scope.NEXT_GEN]
  },
  */






/*

  {
    id: "WETH_SX_SLP:USD",
    query: Coin.WETH_SX_SLP,
    base: Coin.USD,
    liquidityPairId: "WETH_SX_SLP"
  },
  {
    id: "WMATIC_SX_ALP:USD",
    query: Coin.WMATIC_SX_ALP,
    base: Coin.USD,
    liquidityPairId: "WMATIC_SX_ALP"
  },

  {
    id: "MATIC:ETH",
    query: Coin.MATIC,
    base: Coin.ETH,
    token1AsBase: true,
    chainId: Chain.POLYGON,
    platformId: Exchange.SUSHISWAP,
    address: "0xc4e595acDD7d12feC385E5dA5D43160e8A0bAC0E",
    abi: UniswapV2Pair,
    token0: AssetId.WMATIC,
    token1: AssetId.WETH,
  },


import ChainlinkPriceFeed from "../config/abi/ChainlinkPriceFeed.json";

  {
    id: `MATIC:USD:${Chain.POLYGON}`,
    chainId: Chain.POLYGON,
    platformId: Exchange.CHAINLINK,
    address: "0x1bF9805B40a5f69c7d0f9E5d1Ab718642203c652",
    abi: ChainlinkPriceFeed,
    token0: AssetId.MATIC,
    fee: 0
  },

*/
