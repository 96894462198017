import { Contract } from "ethers";
import { Assets } from "../config/assets";
import RatePairs from "../config/rates";
import { fromWei } from "../utils";
import { getRpcProvider } from "./providers";
import { RateType } from "../config/types";

export async function getRateFromPool(ratePairId: string): Promise<number | null> {
  let rate: number | null = null
  try {
    const pairConfig = RatePairs.find(e => e.id === ratePairId)!;
    const { chainId, address, abi, token0, token1, token1AsBase, type } = pairConfig;
    if (chainId && address) {
      const provider = getRpcProvider(chainId);

      const meta0 = Assets.find(meta => meta.assetId === token0 && meta.chainId === chainId)!;
      const meta1 = Assets.find(meta => meta.assetId === token1 && meta.chainId === chainId)!;
      const contract = new Contract(address, abi, provider);

      if (type === RateType.DERIVED_V2) {
        const [reserve0, reserve1] = await contract.getReserves();
        const value0 = fromWei(reserve0, meta0.decimals);
        const value1 = fromWei(reserve1, meta1.decimals);
        const baseValue = token1AsBase ? value1 : value0;
        const queryValue = token1AsBase ? value0 : value1;

        rate = baseValue / queryValue;  
      } else {
        const slot0 = await contract.slot0();
        const sqrtPriceX96 = slot0.sqrtPriceX96;
        const price = (sqrtPriceX96 ** 2) / (2 ** 192);
        const token0Price = price / (10 ** (meta1.decimals - meta0.decimals));
        const token1Price = 1 / token0Price;
    
        rate = token1AsBase ? token0Price : token1Price;
        console.log("-------- getRate", ratePairId, rate);  
      }
      // console.log("-------- getRate", ratePairId, rate);
    }
  } catch (err) {
    console.log("-------- getRate", ratePairId, err);
  } finally {
    return rate
  }
}

/*
export async function getRateFromPoolV3(ratePairId: string): Promise<number | null> {
  let rate: number | null = null;
  try {
    const pair = RatePairs.find(e => e.id === ratePairId)!;
    const { chainId, address, abi, token0, token1, token1AsBase } = pair;
    if (chainId && address) {
      const provider = getRpcProvider(chainId);

      const meta0 = Assets.find(meta => meta.assetId === token0 && meta.chainId === chainId)!;
      const meta1 = Assets.find(meta => meta.assetId === token1 && meta.chainId === chainId)!;
      const contract = new Contract(address, abi, provider);

      const slot0 = await contract.slot0();
      const sqrtPriceX96 = slot0.sqrtPriceX96;
      const price = (sqrtPriceX96 ** 2) / (2 ** 192);
      const token0Price = price / (10 ** (meta1.decimals - meta0.decimals));
      const token1Price = 1 / token0Price;
  
      rate = token1AsBase ? token0Price : token1Price;
      console.log("-------- getRate", ratePairId, rate);
    }
  } catch (err) {
    console.log("-------- getRate", ratePairId, err);
  } finally {
    return rate
  }
}
*/

/*
export async function getRates(): Promise<IRatesState> {
  try {
    const results: any = await Promise.all(
      RatePairs.map(async (pair) => {
        const { chainId, address, abi, token0, token1, fixedRate, token1AsBase } = pair;
        if (fixedRate) {
          return [pair.id, fixedRate];
        } else if (chainId && address) {
          const provider = getRpcProvider(chainId);

          const meta0 = Assets.find(meta => meta.assetId === token0 && meta.chainId === chainId)!;
          const meta1 = Assets.find(meta => meta.assetId === token1 && meta.chainId === chainId)!;
          const contract = new Contract(address, abi, provider);
          const [reserve0, reserve1] = await contract.getReserves();
   
          const value0 = fromWei(reserve0, meta0.decimals);
          const value1 = fromWei(reserve1, meta1.decimals);
          const baseValue = token1AsBase ? value1 : value0;
          const queryValue = token1AsBase ? value0 : value1;

          return [
            pair.id,
            baseValue / queryValue
            // (baseValue - baseValue * pair.fee) / queryValue
          ];  
        }
      })
    );

    const rates: any = results.reduce((acc: IRatesState, entry: [string, number]) => {
      const [id, rate] = entry;
      acc[id] = rate;
      return acc;
    }, {} as IRatesState);
  
    // console.log("-------- getRates", rates);
    return rates;
  } catch (err) {
    console.log("-------- getRates", err);
    return {};
  } 
}
*/

/*


        if (token1) { // use trading pair
        } else { // use price feed
          const meta0 = Assets.find(meta => meta.id === token0 && meta.chainId === chainId)!;
          const contract = new Contract(address, abi, provider);
          const value = await contract.latestAnswer();
          const valueNum = fromWei(value, meta0.decimals);
          return [
            pair.id,
            valueNum + valueNum * pair.fee
          ];
        }

*/
