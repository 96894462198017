import axios from "axios";
import useSWR from "swr";
import { toChecksumAddress } from "ethereum-checksum-address"
import { fromWei } from "../../utils";
import { IStakingHarvestSummary, IStakingHarvestTx, OLD_STAKING_ACCOUNTS } from ".";
import { IScope } from "../../state/app";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "../subgraphs";
import { AssetId, Chain } from "../../config/types";
import { Assets } from "../../config/assets";
import { ITimestampBlockMap, getBlocksByTimestamps } from "../subgraphs/blocks";
import { ITokenRatesHistorical, getTokenRates, getTokenRatesByBlock } from "../subgraphs/tokenRates";
import { getTokenRatesPolygon } from "../subgraphs/tokenRatesPolygon";

const query = /* GraphQL */`
  query($accounts: [String!]!, $startDate: Int, $endDate: Int) {
    stakingPayouts (
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: {      
        staker_in: $accounts
        timestamp_gte: $startDate
        timestamp_lte: $endDate
      }
    ) {
      id
      staker
      amount
      epoch
      timestamp
    }
  }
`
// token

const ENDPOINTS: any = {
  [Chain.SXN]: "https://graph.sx.technology/subgraphs/name/sportx-bet/sportx",
  [Chain.POLYGON]: "https://api.thegraph.com/subgraphs/name/mrwillis/sportx",
}

async function dataFetcher([endpoint, query, variables, chainId]: [endpoint: string, query: string, variables: any, chainId: number]) {
  console.log("------- data fetcher", chainId, endpoint, variables)

  let data: any;
  const response = await axios.post(
    endpoint,
    JSON.stringify({
      query,
      variables,
    }),
    { headers: { "Content-Type": "application/json" }}
  );
  data = response.data;

  let txs: IStakingHarvestTx[] | undefined;
  let summary: IStakingHarvestSummary | undefined;
  const token = Assets.find(e => e.chainId === chainId && e.assetId === AssetId.SX)!
  
  if (data) {
    const { data: { stakingPayouts }} = data;

    // console.log("------ dataFetcher", stakingPayouts)

    txs = stakingPayouts.map((e: any) => ({
      timestamp: Number(e.timestamp),
      //block: 0,
      chainId,
      txHash: e.id.split("-")[0],
      accountAddress: toChecksumAddress(e.staker),
      amounts: {
        [token.assetId]: fromWei(e.amount)
      },
      amountsUSD: {
        [token.assetId]: 0
      },
      currentValuesUSD: {
        [token.assetId]: 0
      },
    }))

    summary = {
      [token.assetId]: {
        tokenAddress: token.address!,
        amount: 0,
        amountUSD: 0,
        currentValueUSD: 0,
      }
    } 

    const timestamps = txs!.map(e => e.timestamp)
    const queryTokens = [token.address!]
    let tokenRates: ITokenRatesHistorical = {}
    let timestampBlockMap: ITimestampBlockMap = {}

    if (chainId === Chain.POLYGON) {
      tokenRates = await getTokenRatesPolygon({
        tokens: [token.address!],
        startDate: timestamps[timestamps.length - 1] - (24 * 60 * 60),
        endDate: timestamps[0]
      })
    
      console.log("------- polygon rates", tokenRates)
    
    } else {
      timestampBlockMap = await getBlocksByTimestamps(timestamps, chainId)  
      tokenRates = await getTokenRatesByBlock({
        chainId,
        queryTokens,
        timestampBlockMap
      })

      // console.log("------ token rates", tokenRates)
    }

    const currentTokenRates = await getTokenRates({
      chainId: Chain.SXN,
      queryTokens: chainId === Chain.SXN ? queryTokens : [Assets.find(e => e.assetId === token.assetId && e.chainId === Chain.SXN)!.address!],
    })

    for (let tx of txs!) {
      const timestamp = Object.keys(tokenRates[token.address!]).reduce((acc, key) => {
        const ts = Number(key)
        if (ts <= tx.timestamp && ts > acc) {
          acc = ts
        }
        return acc
      }, 0)
      const amountUSD = tx.amounts[token.assetId] * (tokenRates[token.address!][timestamp] || 0)
      tx.amountsUSD = {
        [token.assetId]: amountUSD
      }
      const currentValueUSD = tx.amounts[token.assetId] * currentTokenRates[token.assetId]
      summary[token.assetId].amount += tx.amounts[token.assetId]
      summary[token.assetId].amountUSD += amountUSD
      summary[token.assetId].currentValueUSD += currentValueUSD
    }
  }

  return { txs, summary };
}

export function useOldStaking(shouldFetch: boolean, scope: IScope, chainId: number) {
  console.log("-------- useOldStaking", chainId)

  const swrOptions = {
    revalidateOnFocus: false,
  };

  const endpoint = ENDPOINTS[chainId];
  const variables = {
    accounts: OLD_STAKING_ACCOUNTS,
    startDate: scope.startDate || DEFAULT_START_DATE,
    endDate: scope.endDate || DEFAULT_END_DATE,
  }
  
  const fetchData = shouldFetch;

  const { data, error } = useSWR(
    fetchData ? [endpoint, query, variables, chainId] : null, 
    dataFetcher,
    swrOptions
  );

  if (error) {
    console.log("-------- fetching staking data error", error);
  }
  console.log("--------- old staking data", data);


  return { data, error };
}
