import { Pie } from 'react-chartjs-2';
import { useRecoilValueLoadable } from 'recoil';
import { valuesByCategorySelector } from '../../state/byCategory';
import { lv } from '../../utils';
import { ChartColors } from "./utils";

export function ByCategory() {

  const summary = lv(useRecoilValueLoadable(valuesByCategorySelector));
  if (!summary) return null;
  
  const labels = Object.keys(summary);
  const values = Object.keys(summary).map(key => summary[key].toFixed());
  const total = Object.keys(summary).reduce((acc, key) => acc + summary[key], 0);

  // console.log('------- by catgory', summary);

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: ChartColors,
      }
    ]
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const { label: group, formattedValue, parsed } = context;
            const pct = (100 * parsed / total).toFixed(1);
            return [` ${group}`, ` $${formattedValue}`, ` ${pct}%`];
          }
        }
      },
      legend: {
        labels: {
          filter: (item: any, chart: any) => {
            return Number(chart.datasets[0].data[item.index]) > 0;
          }
        }
      }
    }
  };

  // console.log('------- by catgory data', data);

  return (
    <Pie data={data} options={options} />
  );

}
