import { tokens as sxnTokens, lps as sxnLps, farms as sxnFarms } from "./sxn"
import { tokens as sxrTokens, lps as sxrLps, farms as sxrFarms } from "./sxr"
import { tokens as polygonTokens, lps as polygonLps, farms as polygonFarms } from "./polygon"
import { tokens as mainnetTokens, lps as mainnetLps, farms as mainnetFarms } from "./mainnet"
import { tokens as arbitrumTokens } from "./arbitrum"
import { tokens as torontoTokens, lps as torontoLps, farms as torontoFarms } from "./toronto"
import { tokens as bitcoinTokens } from "./bitcoin"
import { IAsset, IFarm, ILiquidityPair, IToken } from "../types";

/*
export const Assets: IAsset[] = [
  ...sxnTokens, ...sxnLps, ...sxnFarms, 
  ...polygonTokens, ...polygonLps,
  ...mainnetTokens, ...mainnetLps, ...mainnetFarms, 
  ...bitcoinTokens,
];
*/

export const Tokens: IToken[] = [
  ...mainnetTokens, ...arbitrumTokens, ...bitcoinTokens, ...sxnTokens, ...sxrTokens, ...polygonTokens, ...torontoTokens,
]

export const LPs: ILiquidityPair[] = [
  ...mainnetLps, ...sxnLps, ...sxrLps, ...polygonLps, ...torontoLps,
]

export const Farms: IFarm[] = [
  ...mainnetFarms, ...sxnFarms, ...sxrFarms, ...polygonFarms, ...torontoFarms,
]

export const Assets: IAsset[] = [...Tokens, ...LPs, ...Farms]
