
import { css } from "@emotion/react"

export function getTableStyles() {
  return {
    container: css`
      position: relative;
      // max-width: 1200px;
      th, td {
        padding: 5px 10px;
      }
    `,
    headerCell: css`
      text-align: right;
    `,
    balancesCell: css`
      text-align: right;
    `,
    hash: css`
      font-size: 11px;
    `,
    counter: css`
      position: absolute;
      right: 5px;
      top: 3px;
    `,
    extraPrefix: css`
      font-size: 10px;
      color: gray;
      // margin-right: 5px;
    `
  }
}

