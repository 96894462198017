import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin, IFarm, ILiquidityPair, IToken, SubgraphType } from "../types"
import MinTokenInfo from "../abi/MinTokenInfo.json";
import MinPairInfo from "../abi/MinPairInfo.json";
import MinFarmInfo from "../abi/MinFarmInfo.json";
import Staking from "../abi/Staking.json";
import StakingV2 from "../abi/StakingV2.json";

const tokens: IToken[] = [
  {
    id: `${AssetId.SX_NATIVE}:${Chain.SXR}`,
    assetId: AssetId.SX_NATIVE,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.NATIVE,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXR,
    decimals: 18,
  },
  {
    id: `${AssetId.SX}:${Chain.SXR}`,
    assetId: AssetId.SX,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXR,
    address: "0x3E96B0a25d51e3Cc89C557f152797c33B839968f",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SX_DEPRECATED}:${Chain.SXR}`,
    assetId: AssetId.SX_DEPRECATED,
    coin: Coin.DEPRECATED,
    groupId: AssetGroupId.SX,
    type: AssetType.DEPRECATED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXR,
    address: "0xcf99b7Ac75121e2B2Ad2927182178B3837d76a83",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.USDC}:${Chain.SXR}`,
    assetId: AssetId.USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.SXR,
    address: "0x6629Ce1Cf35Cc1329ebB4F63202F3f197b3F050B",
    decimals: 6,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.WETH}:${Chain.SXR}`,
    assetId: AssetId.WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.SXR,
    address: "0x0fa861219B9010DeDd4Fe812d9140Ae17927786D",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SHARK}:${Chain.SXR}`,
    assetId: AssetId.SHARK,
    coin: Coin.SHARK,
    groupId: AssetGroupId.SHARK,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXR,
    address: "0xa0cB58E7F783fce0F4042C790ea3045c48CD51e8",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SX_STAKED_V2}:${Chain.SXR}`,
    assetId: AssetId.SX_STAKED_V2,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_STAKED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXR,
    address: "0xD9fAB25fA5AFB3d9E698dE87B8d0274A37ba76F6",
    decimals: 18,
    abi: StakingV2,
  },
  {
    id: `${AssetId.SX_PENDING_V2}:${Chain.SXR}`,
    assetId: AssetId.SX_PENDING_V2,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_PENDING,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXR,
    address: "0xD9fAB25fA5AFB3d9E698dE87B8d0274A37ba76F6",
    decimals: 18,
    abi: StakingV2,
  },
]

const lps: ILiquidityPair[] = [
  {
    id: `${AssetId.SX_USDC_KLP}:${Chain.SXR}`,
    assetId: AssetId.SX_USDC_KLP,
    coin: Coin.SX_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0x32dEe3b85E7F0b5EB2E19D2124F5bc273D076Db3",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SX_WETH_KLP}:${Chain.SXR}`,
    assetId: AssetId.SX_WETH_KLP,
    coin: Coin.SX_WETH_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0x62DA46d5029674155FbcE9C6FA1da2C31292DF0F",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WETH, AssetId.SX],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.WETH_USDC_KLP}:${Chain.SXR}`,
    assetId: AssetId.WETH_USDC_KLP,
    coin: Coin.WETH_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0xC91c28b45d6f756F6e8ef35B9D2b691804ecC7dA",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WETH, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SHARK_USDC_KLP}:${Chain.SXR}`,
    assetId: AssetId.SHARK_USDC_KLP,
    coin: Coin.SHARK_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0x42cB36F28f014687835F647A3b144A5df7cce6c1",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.USDC, AssetId.SHARK],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.SX_SHARK_KLP}:${Chain.SXR}`,
    assetId: AssetId.SX_SHARK_KLP,
    coin: Coin.SX_SHARK_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0x6bAF120D1A2d463D124df6873fCc3B0345A19d07",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.SHARK],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
]

const farms: IFarm[] = [
  {
    id: `${AssetId.SHARK_USDC_KLP_STAKED}:${Chain.SXR}`,
    assetId: AssetId.SHARK_USDC_KLP_STAKED,
    coin: Coin.SHARK_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0x1F5920a4CDcE05cFF08a43e0a35632A173d5Ca15",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SHARK_USDC_KLP],
    pid: 0,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SX_USDC_KLP_STAKED}:${Chain.SXR}`,
    assetId: AssetId.SX_USDC_KLP_STAKED,
    coin: Coin.SX_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXR,
    address: "0x1F5920a4CDcE05cFF08a43e0a35632A173d5Ca15",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_USDC_KLP],
    pid: 1,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
]

export { tokens, lps, farms }
