/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { Accounts } from "../../config/accounts";
import { AssetGroupId, Fiat } from "../../config/types";
import { Wallets } from "../../config/wallets";
import { balanceState } from "../../state/balances";
import { IValuesByAccountSelector, valuesByAccountSelector } from "../../state/byAccount";
import { balancesByGroupSelector, IBalancesByGroup } from "../../state/byGroup";
import { balanceFormatter, lv } from "../../utils";
import Balance from "./Balance";
import { Assets } from "../../config/assets";
import { scopeSelector } from "../../state/app";

function getStyles() {
  return {
    container: css`
      min-width: 1200px;
      th, td {
        padding: 5px 10px;
      }
    `,
    headerCell: css`
      text-align: right;
    `,
    balancesCell: css`
      text-align: right;
    `,
    balanceBox: css`
      text-align: right;
      p {
        text-align: right;
      }
    `,
    networkIcon: css`
      width: 9px;
      margin-right: 3px;
      margin-top: 2px;
    `,
    footer: css`
      td {
        color: rgba(0, 0, 0, 0.87);
      }
    `,
    polygon: css`
      color: #5f008b;
      font-weight: 400;
    `,
    prefix: css`
    font-size: 9px;
    color: #535353;
    margin-right: 5px;
  `,
}
}

function Balances() {
  const MIN_USD_TO_SHOW = 0.01
  const ALWAYS_SHOW = [
    AssetGroupId.ETH,
    AssetGroupId.SX,
    AssetGroupId.SX_STAKED,
    // AssetGroupId.SHARK,
    AssetGroupId.USDC,
    AssetGroupId.BTC,
    AssetGroupId.MATIC
  ];

  const styles = getStyles();

  const balancesByGroup: IBalancesByGroup = lv(useRecoilValueLoadable(balancesByGroupSelector));
  const values: IValuesByAccountSelector = lv(useRecoilValueLoadable(valuesByAccountSelector));
  const { valuesByAccount, totalValue, totalValueWithoutStaked } = values || {};
  const balances = useRecoilValue(balanceState);
  const groups = Object.values(AssetGroupId);
  const { accounts: scopeAccounts, chains, coins, excludedCoins } = useRecoilValue(scopeSelector)

  console.log("-------- scopeAccounts", scopeAccounts.length, scopeAccounts)

  return (
    <TableContainer css={styles.container} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Account</Typography>
            </TableCell>
            {
              groups.map(group => {
                const showGroup = balancesByGroup?.[group]?.[Fiat.USD] >= MIN_USD_TO_SHOW || ALWAYS_SHOW.includes(group);
                return (
                  showGroup ?
                  <TableCell css={styles.headerCell} key={group}>
                    <Typography variant="subtitle2">{group.replace(/_/g, " ")}</Typography>
                  </TableCell> : null
                )
              })
            }
            <TableCell css={styles.headerCell}>
              <Typography variant="subtitle2">Total USD</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Wallets.map(wallet => {
            const accounts = Accounts.filter(e => scopeAccounts.includes(e.address) && e.walletId === wallet.id)
            return (
              accounts.map(account => {
                return (
                  <TableRow key={account.address}>
                    <TableCell>
                      <Typography variant="body2">{`${account.walletId} ${account.label}`}</Typography>
                      <Typography variant="caption" fontSize={11} color="GrayText">{account.address}</Typography>
                    </TableCell>
                    {
                      groups.map(group => {
                        const assets = Assets.filter(asset => asset.groupId === group);
                        const showGroup = balancesByGroup?.[group]?.[Fiat.USD] >= MIN_USD_TO_SHOW || ALWAYS_SHOW.includes(group);
                        return (
                          showGroup ?
                          <TableCell css={styles.balancesCell} key={group}>
                            {
                              assets.map(asset => (
                                <Balance 
                                  key={asset.id}
                                  asset={asset}
                                  balance={balances[asset.id] ? balances[asset.id][account.address] : undefined} 
                                />
                              ))
                            }
                          </TableCell> : null
                        )
                      })
                    }
                    <TableCell css={styles.balanceBox}>
                      {
                        valuesByAccount ?
                        <Typography variant="subtitle2">
                          {balanceFormatter(valuesByAccount[account.address], { prefix: "$" })}
                        </Typography> : <Skeleton width={50} />
                      }
                    </TableCell>
                  </TableRow>
                )
              })
            )
          })}
        </TableBody>
        <TableFooter css={styles.footer}>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Total</Typography>
            </TableCell>
            {
              groups.map(group => {
                const currencies = Object.keys((balancesByGroup || {})[group] || []).filter(e => e !== Fiat.USD);
                const showGroup = balancesByGroup?.[group]?.[Fiat.USD] >= MIN_USD_TO_SHOW || ALWAYS_SHOW.includes(group);
                return (
                  showGroup ?
                  <TableCell css={styles.balanceBox} key={group}>
                    {
                      currencies.map(coin => (
                        <Typography key={coin} variant="subtitle2">
                          {balancesByGroup[group][coin] ? <small css={styles.prefix}>{coin}</small> : ""}
                          {balanceFormatter(balancesByGroup[group][coin], { coin, usePrefix: false })}
                        </Typography>
                      ))
                    }
                  </TableCell> : null
                )
              })
            }
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Total USD</Typography>
            </TableCell>
            {
              groups.map(group => {
                const showGroup = balancesByGroup?.[group]?.[Fiat.USD] >= MIN_USD_TO_SHOW || ALWAYS_SHOW.includes(group);
                return (
                  showGroup ?
                  <TableCell css={styles.balanceBox} key={group}>
                    <Typography variant="subtitle2">
                      {balanceFormatter(balancesByGroup?.[group]?.[Fiat.USD], { prefix: "$" })}
                    </Typography>
                  </TableCell> : null
                )
              })
            }
            <TableCell css={styles.balancesCell}>
              <Typography variant="subtitle2">{balanceFormatter(totalValue, { prefix: "$" })}</Typography>
              <Typography variant="subtitle2">{balanceFormatter(totalValueWithoutStaked, { prefix: "$" })}</Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table> 
    </TableContainer>
 );
}

export default Balances;
