/** @jsxImportSource @emotion/react */
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material"
import { Chain } from "../../config/types"
import { useSwapTransactions } from "../../services/subgraphs"
import { coinOptions } from "../../state/app"
import { balanceFormatter, getAccountLabel, timestampFormatter } from "../../utils"
import { getTableStyles } from "../shared/styles"

function Transactions() {
  const styles = getTableStyles()

  const swaps = useSwapTransactions()

  // const lps = useLpTransactions()
  // console.log("----------- lps", lps)

  let totalsIn: any = {}
  let totalsOut: any = {}
  let totalsUSD: any = 0

  for (let swap of swaps) {
    totalsIn[swap.coinIn] = (totalsIn[swap.coinIn] || 0) - swap.amountIn
    totalsOut[swap.coinOut] = (totalsOut[swap.coinOut] || 0) + swap.amountOut
    totalsUSD += swap.amountUSD || 0
  }
  const cols = coinOptions.filter(e => [...Object.keys(totalsIn), ...Object.keys(totalsOut)].includes(e))

  // console.log("---------- swaps", swaps)

  return (
    <Box position="relative">
      
      <Box>
        <Stack direction="row" spacing={2} padding={2}>
          <TableContainer css={styles.container} component={Paper}>
            <Typography css={styles.counter} variant="body2">{swaps.length}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">Account</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">Chain</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">Xchg</Typography>
                  </TableCell>
                  {
                    cols.map(coin => (
                      <TableCell align="right" key={coin}>
                        <Typography variant="subtitle2">{coin}</Typography>
                      </TableCell>
                    ))
                  }
                  <TableCell align="right">
                    <Typography variant="subtitle2">Rate</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">USD value</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">Tx hash</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  swaps.map((swap, i) => (
                    <TableRow key={swap.txHash}>
                      <TableCell>
                        {timestampFormatter(swap.timestamp)}
                      </TableCell>
                      <TableCell>
                        {getAccountLabel(swap.account)}
                        {/* swap.account */}
                      </TableCell>
                      <TableCell>
                        {Chain[swap.chainId].toLowerCase()}
                      </TableCell>
                      <TableCell>
                        {swap.exchange.toLowerCase()}
                      </TableCell>
                      {
                        cols.map(coin => {
                          return (
                            <TableCell align="right" key={`${i}:${coin}`}>
                              {swap.coinIn === coin ? balanceFormatter(-swap.amountIn, { coin }) : ""}
                              {swap.coinOut === coin ? balanceFormatter(swap.amountOut, { coin }) : ""}
                            </TableCell> 
                          )
                        })
                      }
                      <TableCell align="right">
                        {`${balanceFormatter(swap.amountIn/swap.amountOut)}/${balanceFormatter(swap.amountOut/swap.amountIn)}`}
                      </TableCell>
                      <TableCell align="right">
                        {swap.amountUSD ? balanceFormatter(swap.amountUSD) : ""}
                      </TableCell>
                      <TableCell css={styles.hash}>
                        {swap.txHash}
                      </TableCell>

                    </TableRow>
                  ))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">Total</Typography>
                  </TableCell>  
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  {
                    cols.map(coin => (
                        <TableCell align="right" key={`total:${coin}`}>
                          {balanceFormatter(totalsIn[coin], { coin })}<br />
                          {balanceFormatter(totalsOut[coin], { coin })}<br />
                          {balanceFormatter(totalsIn[coin] + totalsOut[coin], { coin })}

                        </TableCell> 
                      )
                    )
                  }
                  <TableCell />
                  <TableCell align="right" >
                    {balanceFormatter(totalsUSD)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Box>

    </Box>
  )
}

export default Transactions

/*

                <TableCell>
                  <Typography variant="subtitle2">From token</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">To token</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">From amount</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">To amount</Typography>
                </TableCell>

                    <TableCell>
                      {tokenIn ? tokenIn.assetId : swap.addressIn}
                    </TableCell>
                    <TableCell>
                      {tokenOut ? tokenOut.assetId : swap.addressOut}
                    </TableCell>
                    <TableCell align="right">
                      {balanceFormatter(swap.amountIn)}
                    </TableCell>
                    <TableCell align="right">
                      {balanceFormatter(swap.amountOut)}
                    </TableCell>


*/
