import { Chain, Exchange, Fiat, SubgraphType } from "./types"

export const VALUE_BASE = Fiat.USD

interface ISubgraphEndpoints {
  [chainId: number]: {
    [exchanhe: string]: {
      [subgraphType: string]: string;
    }
  }
}

export const subgraphEndpoints: ISubgraphEndpoints = {
  [Chain.SXR]: {
    [Exchange.SHARKSWAP]: {
      [SubgraphType.MINICHEF]: "https://rollup-graph.sx.technology/subgraphs/name/sharkswap/minichef",
      [SubgraphType.EXCHANGE]: "https://rollup-graph.sx.technology/subgraphs/name/sharkswap/exchange"
    }
  },
  [Chain.SXN]: {
    [Exchange.SHARKSWAP]: {
      [SubgraphType.MINICHEF]: "https://graph.sx.technology/subgraphs/name/sharkswap/minichef",
      [SubgraphType.EXCHANGE]: "https://graph.sx.technology/subgraphs/name/sharkswap/exchange"
    }
  },
  [Chain.TORONTO]: {
    [Exchange.SHARKSWAP]: {
      [SubgraphType.MINICHEF]: "https://graph.toronto.sx.technology/subgraphs/name/sharkswap/toronto-minichef",
      [SubgraphType.EXCHANGE]: "https://graph.toronto.sx.technology/subgraphs/name/sharkswap/toronto-exchange"
    }
  },
  [Chain.POLYGON]: {
    [Exchange.PARASWAP]: {
      [SubgraphType.PARASWAP]: "https://api.thegraph.com/subgraphs/name/paraswap/paraswap-subgraph-polygon"
    },
    [Exchange.SUSHISWAP]: {
      [SubgraphType.EXCHANGE]: "https://api.thegraph.com/subgraphs/name/sushiswap/matic-exchange"
    },
    [Exchange.QUICKSWAP]: {
      [SubgraphType.EXCHANGE]: "https://api.thegraph.com/subgraphs/name/sameepsi/quickswap-v3"
    }
  }
}

export const sxbetSubgraph = "https://graph.sx.technology/subgraphs/name/sx-bet/production";
export const sxbetRollupSubgraph = "https://rollup-graph.sx.technology/subgraphs/name/sx-bet/production";
