/** @jsxImportSource @emotion/react */
import { Box, Checkbox, Chip, Input, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useRecoilState } from "recoil";
import { Preset } from "../../config/types";
import { selectedPresetsState } from "../../state/app";
import { getStyles } from "./styles";

function PresetSelector() {
  const [presets, setPresets] = useRecoilState(selectedPresetsState)
  const styles = getStyles();

  const handleChange = (event: SelectChangeEvent<Preset[]>) => {
    const { target: { value }} = event;
    setPresets(value as Preset[]);
  };

  return (
    <Select
      multiple
      value={presets}
      onChange={handleChange}
      input={<Input disableUnderline />}
      renderValue={(selected) => (
        <Box css={styles.selector} display="flex">
          {selected.map((value) => (
            <Chip key={value} label={value} size="small" />
          ))}
        </Box>
      )}
    >
      {Object.values(Preset).map(preset => (
        <MenuItem
          key={preset}
          value={preset}
        >
          <Checkbox checked={presets.includes(preset as Preset)} />
          {preset}
        </MenuItem>
      ))}
    </Select>
  )
}

export default PresetSelector
