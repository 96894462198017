/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import _ from "lodash"
import { Fragment } from "react"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import ratePairs from "../../config/rates"
import { IRatePair, RateType } from "../../config/types"
import { selectedPresetsState } from "../../state/app"
// import { useScope } from "../../hooks"
import { rateQuery } from "../../state/rates"
import { balanceFormatter, lv } from "../../utils"

function getStyles() {
  return {
    container: css`
      max-width: 1200px;
      th, td {
        padding: 5px 10px;
      }
    `,
    headerCell: css`
      text-align: right;
    `,
    balancesCell: css`
      text-align: right;
    `,
  }
}

function Rate(params: { id: string }) {
  const { id } = params;
  // console.log("------ Rate", id)
  const rate = lv(useRecoilValueLoadable(rateQuery(id)))
  return (
    <Fragment>
      {rate !== undefined ? balanceFormatter(rate, { pos: rate >= 10 ? undefined : 3 }) : <Skeleton width={50} />}
    </Fragment>
  )
}

function sortRows(a: IRatePair, b: IRatePair) {
  const order = ["SX", "SHARK", "ETH", "BTC", "MATIC"]
  let aIndex = order.indexOf(a.query!)
  let bIndex = order.indexOf(b.query!)
  if (aIndex === -1) aIndex = 1000
  if (bIndex === -1) bIndex = 1000
  return aIndex > bIndex 
  ? 1
  : bIndex > aIndex
  ? -1
  : 0
}

function Rates() {
  const styles = getStyles()
  const presets = useRecoilValue(selectedPresetsState)
  const cols = ["USD", "SX", "SHARK"]

  const rows = presets ? _.uniq(ratePairs
    .filter(e => e.presets!.some(preset => presets.includes(preset)) && e.type !== RateType.COMPLEX)
    .sort(sortRows)
    .map(e => e.query)
  ) : []

  return (
    <TableContainer css={styles.container} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Asset</Typography>
            </TableCell>
            {
              cols.map(col => (
                <TableCell css={styles.headerCell} key={col}>
                  <Typography variant="subtitle2">{col}</Typography>
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map(row => (
              <TableRow key={row}>
                <TableCell>
                  {row}
                </TableCell>
                {
                  cols.map(col => {
                    return (
                      <TableCell css={styles.balancesCell} key={`${row}:${col}`}>
                        <Rate id={`${row}:${col}`} />
                      </TableCell> 
                    )
                  })
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Rates
