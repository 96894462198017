import { selector } from "recoil";
import { Accounts } from "../config/accounts";
import { Assets } from "../config/assets";
import { AssetId } from "../config/types";
import { balancesWithValuesSelector } from "./balances";

export interface IValuesByAccount {
  [account: string]: number;
}

export interface IValuesByAccountSelector {
  valuesByAccount: IValuesByAccount;
  totalValue: number;
  totalValueWithoutStaked: number;
}

export const valuesByAccountSelector = selector({
  key: "ValuesByAccount",
  get: ({ get }): IValuesByAccountSelector => {
    const balancesWithValues = get(balancesWithValuesSelector);

    let valuesByAccount: IValuesByAccount = {};
    let totalValue = 0;
    let totalValueWithoutStaked = 0;

    for (let account of Accounts.map(e => e.address)) {
      let accountTotal = 0;
      for (let asset of Assets) {
        const { id: assetUniqueId, assetId } = asset;
        const value = (balancesWithValues[assetUniqueId] && balancesWithValues[assetUniqueId][account]
          ? (balancesWithValues[assetUniqueId][account][1] || 0) : 0);
        accountTotal += value;
        totalValue += value;
        if (![AssetId.SX_STAKED_V2, AssetId.SX_STAKED, AssetId.SX_PENDING, AssetId.SX_PENDING_V2].includes(assetId)) {
          totalValueWithoutStaked += value;
        }
      }
      valuesByAccount[account] = accountTotal;
    }
    return {
      valuesByAccount,
      totalValue,
      totalValueWithoutStaked
    };
  }
});
