import { IToken, ILiquidityPair, IFarm } from "../types"
import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin } from "../types" // , SubgraphType
import MinTokenInfo from "../abi/MinTokenInfo.json";
// import MinPairInfo from "../abi/MinPairInfo.json";
// import MinFarmInfo from "../abi/MinFarmInfo.json";
import StakingV2 from "../abi/StakingV2.json";

const tokens: IToken[] = [
  {
    id: `${AssetId.SX_NATIVE}:${Chain.TORONTO}`,
    assetId: AssetId.SX_NATIVE,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.NATIVE,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.TORONTO,
    decimals: 18,
  },
  {
    id: `${AssetId.SX}:${Chain.TORONTO}`,
    assetId: AssetId.SX,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.TORONTO,
    address: "0x2D4e10Ee64CCF407C7F765B363348f7F62D2E06e",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SHARK}:${Chain.TORONTO}`,
    assetId: AssetId.SHARK,
    coin: Coin.SHARK,
    groupId: AssetGroupId.SHARK,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.TORONTO,
    address: "0x5846A00372C5fA57AaE2B6dBfC5439A8FbfBA596",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.USDC}:${Chain.TORONTO}`,
    assetId: AssetId.USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.TORONTO,
    address: "0x5147891461a7C81075950f8eE6384e019e39ab98",
    decimals: 6,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.WETH}:${Chain.TORONTO}`,
    assetId: AssetId.WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.TORONTO,
    address: "0x93964E8d07585672b4d38E5F88E674fF2C418B02",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.WMATIC}:${Chain.TORONTO}`,
    assetId: AssetId.WMATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.TORONTO,
    address: "0x8e3Daba8F5EaE7c1034EdD6257B6EfA04d8b17E8",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SX_STAKED_V2}:${Chain.TORONTO}`,
    assetId: AssetId.SX_STAKED_V2,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_STAKED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.TORONTO,
    address: "0xAEb6Cf65c48064aF0FA8554199CB8eAd499D92A5",
    decimals: 18,
    abi: StakingV2,
  },

]

const lps: ILiquidityPair[] = [
  /*
  {
    id: `${AssetId.USDC_SHARK_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.USDC_SHARK_TLP,
    coin: Coin.USDC_SHARK_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x64688a3468C926c6DA64b3229f6cDd5a26c044D7",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.USDC, AssetId.SHARK],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.USDC_WETH_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.USDC_WETH_TLP,
    coin: Coin.USDC_WETH_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x4517563DF811E58B345D1FF2E1B753D26720f08A",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.USDC, AssetId.WETH],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WSX_WETH_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.WSX_WETH_TLP,
    coin: Coin.WSX_WETH_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x8626Ba852bAC803177fba1c64E2983dF3A45aA3d",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.WETH],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WSX_USDC_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.WSX_USDC_TLP,
    coin: Coin.WSX_USDC_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x94FCb41c60ca28baf9b8A11ca7328D590Bc51541",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WSX_SHARK_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.WSX_SHARK_TLP,
    coin: Coin.WSX_SHARK_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0xDB6C40E9FED7bE7fDA49E5923D8a7d7e445a2968",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.SHARK],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.USDC_WMATIC_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.USDC_WMATIC_TLP,
    coin: Coin.USDC_WMATIC_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x3fe3c25c105289576CfB895Ff97665DAcBAbB8d4",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.USDC, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WMATIC_WETH_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.WMATIC_WETH_TLP,
    coin: Coin.WMATIC_WETH_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x7547E3c1B0A3bD50764c2E96FEad4946566EF969",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WMATIC, AssetId.WETH],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WSX_WMATIC_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.WSX_WMATIC_TLP,
    coin: Coin.WSX_WMATIC_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0xA46a4D6B21350d3a228CbecB56a3653c202bf7Eb",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.SHARK_WETH_TLP}:${Chain.TORONTO}`,
    assetId: AssetId.SHARK_WETH_TLP,
    coin: Coin.SHARK_WETH_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0xAA79E4bbbaBa7134E0C4a255b9604B335DE9a640",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SHARK, AssetId.WETH],
    subgraphTypes: [SubgraphType.EXCHANGE]
  }, 
  */
]

const farms: IFarm[] = [
  /*
  {
    id: `${AssetId.WSX_WETH_TLP_STAKED}:${Chain.TORONTO}`,
    assetId: AssetId.WSX_WETH_TLP_STAKED,
    coin: Coin.WSX_WETH_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x7c38FC39a5035Be9e2e03798c8CD5DFc94D1E54C",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.WSX_WETH_TLP],
    pid: 0,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.WSX_USDC_TLP_STAKED}:${Chain.TORONTO}`,
    assetId: AssetId.WSX_USDC_TLP_STAKED,
    coin: Coin.WSX_USDC_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x7c38FC39a5035Be9e2e03798c8CD5DFc94D1E54C",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.WSX_USDC_TLP],
    pid: 1,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.USDC_WETH_TLP_STAKED}:${Chain.TORONTO}`,
    assetId: AssetId.USDC_WETH_TLP_STAKED,
    coin: Coin.USDC_WETH_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x7c38FC39a5035Be9e2e03798c8CD5DFc94D1E54C",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.USDC_WETH_TLP],
    pid: 5,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  */
]

export { tokens, lps, farms }

/*

  {
    id: `${AssetId.USDC_SHARK_TLP_STAKED}:${Chain.TORONTO}`,
    assetId: AssetId.USDC_SHARK_TLP_STAKED,
    coin: Coin.USDC_SHARK_TLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.TORONTO,
    address: "0x7c38FC39a5035Be9e2e03798c8CD5DFc94D1E54C",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.USDC_SHARK_TLP],
    pid: 3,
    subgraphTypes: [SubgraphType.MINICHEF]
  },

*/
