import { selectorFamily } from "recoil";
import { Assets, LPs } from "../config/assets";
import { Coin, Fiat, RateType } from "../config/types";
import { getRateFromPool } from "../services/rates";
import { liquidityPairState } from "./liquidityPairs";
import RatePairs from "../config/rates"

/*

export const ratesState = atomFamily({
  key: "Rates",
  default: async (ratePairId: string) => (
    getRate(ratePairId)
  )
});

function getDerivedRate(params: { ratePairId: string, get: any }) {
  const { ratePairId, get } = params;

  const rate = get(ratesState(ratePairId))
  return rate;
}
*/

function getIndirectRate(params: { path: string[], query: Coin, base: Coin | Fiat, get: any }) {
  const { path, query, base, get } = params;

  const pair0 = RatePairs.find(e => e.id === path[0])!;
  const value0 = get(rateQuery(pair0.id)) || 0; //ratesState
  let rate: number;

  //if (query === Coin.ETH && base === Coin.SHARK) {
  //  console.log("-------- getIndirectRate", pair0, value0)
  //} 

  if (path.length === 1) {
    rate = (pair0.query === query ? value0 : 1 / value0); //value0;
  } else {
    const rate0 = (pair0.query === query ? value0 : 1 / value0);
    const pair1 = RatePairs.find(e => e.id === path[1])!;
    const value1 = get(rateQuery(pair1.id)) || 0; // ratesState
    const rate1 = (pair1.base === base ? value1 : 1 / value1);
  
    rate = rate0 * rate1;
  }
  return rate !== undefined ? rate : null;
}

function getComplexRate(params: { liquidityPair: string, base: Coin | Fiat, get: any }) {
  const { liquidityPair, base, get } = params;
  const lp = get(liquidityPairState(liquidityPair))!;
  const meta = LPs.find(e => e.id === liquidityPair)!;

  const token0 = meta.relatedAssetIds![0];
  const token1 = meta.relatedAssetIds![1];
  const meta0 = Assets.find(e => e.assetId === token0 && e.chainId === meta.chainId)!;
  const meta1 = Assets.find(e => e.assetId === token1 && e.chainId === meta.chainId)!;
  const rate0: any = get(rateQuery(`${meta0.coin}:${base}`));
  const rate1: any = get(rateQuery(`${meta1.coin}:${base}`));

  let rate: number | undefined = undefined;
  const { reserve0, reserve1, totalSupply } = lp;
  if (totalSupply && rate0 !== null && rate1 !== null) {
    rate = (reserve0 * rate0 + reserve1 * rate1) / totalSupply;    
  }
  return rate !== undefined ? rate : null;
}

export const rateQuery = selectorFamily<number | null, string>({
  key: "RateQuery",
  get: ratePairId => async ({ get }) => {
    const pair = RatePairs.find(e => e.id === ratePairId);
    if (!pair) return null;

    const { type, query, base, path, fixedRate, fee, liquidityPair } = pair;

    let rate: number | null | undefined = undefined;
    if (type === RateType.COMPLEX) {      
      rate = getComplexRate({ liquidityPair: liquidityPair!, base: base!, get });
    } else if (type === RateType.FIXED) {
      rate = fixedRate!;
    } else if (type === RateType.INDIRECT) {
      rate = getIndirectRate({ path: path!, query: query!, base: base!, get });
    } else if ([RateType.DERIVED_V2, RateType.DERIVED_V3].includes(type)) {
      rate = await getRateFromPool(ratePairId);
    }
    // console.log("------ rateQuery", ratePairId, rate);

    if (rate !== undefined && rate !== null) {
      rate = rate - (rate * (fee || 0));
    }
    return rate !== undefined ? rate : null;
  }
});

      //console.log("------- rateQuery pair", pair.id, pair.query, pair.base);
      //console.log("------- rateQuery pair0", pair0.id, pair0.query, pair0.base, value0);
      //console.log("------- rateQuery pair1", pair1.id, pair1.query, pair1.base, value1);

      /*
      const lp = get(liquidityPairSelector(liquidityPair!))!;
      rate = lp.rate!;

      const lp = get(liquidityPairState(liquidityPair))!;
      const asset = Assets.find(e => e.id === liquidityPair)!;
      const asset0 = Assets.find(e => e.assetId === asset.relatedAssetIds![0])!;
      const asset1 = Assets.find(e => e.assetId === asset.relatedAssetIds![1])!;
      //const rate0 = getRateByPath({ path: path0!, query, base, get });
      //const rate1 = getRateByPath({ path: path1!, query, base, get });
      const rate0 = getRateByPath({ path: path0!, query: asset0.coin, base, get });
      const rate1 = getRateByPath({ path: path1!, query: asset1.coin, base, get });
      console.log("------ rateQuery debug1", ratePairId, asset0, asset1, rate0, rate1)

      rate = (lp.reserve0 * rate0 + lp.reserve1 * rate1) / lp.totalSupply;
      console.log("------ rateQuery debug2", ratePairId, lp, path0, path1)
      */


      /*
      const pair0 = RatePairs.find(e => e.id === path[0])!;
      const pair1 = RatePairs.find(e => e.id === path[1])!;

      const value0 = get(ratesState(pair0.id)) || 0;
      const value1 = get(ratesState(pair1.id)) || 0;

      const rate0 = (pair0.query === query ? value0 : 1 / value0);
      const rate1 = (pair1.base === base ? value1 : 1 / value1);

      rate = rate0 * rate1;
      */

      /*
      
      else if (liquidityPairId) {
      const lpMeta = LiquidityPairs.find(e => e.id === liquidityPairId)!;
      const meta0 = Assets.find(e => e.assetId === lpMeta.token0 && e.chainId === lpMeta.chainId)!;
      const meta1 = Assets.find(e => e.assetId === lpMeta.token1 && e.chainId === lpMeta.chainId)!;

      const lp = get(liquidityPairState(liquidityPairId));
      if (lp) {
        rate = (lp.reserve0 * rate0 + lp.reserve1 * rate1) / lp.totalSupply;
      }

      */