/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react"
import { Web3Provider } from "@ethersproject/providers"
import { Box, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, Link } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Fragment, useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core"
import { useRecoilState } from "recoil"
import { ConnectorTypes, getConnector } from "../../connectors"
import BalanceFetcher from "../dashboard/BalanceFetcher"
import { useEagerConnect, useInactiveListener } from "../../connectors/hooks"
// import DebugObserver from "./DebugObserver"
import { DebugButton } from "./DebugButton"
import { Outlet, useParams } from "react-router-dom"
// import { useScope } from "../../hooks"
import { Page, Preset } from "../../config/types"
import { selectedPresetsState } from "../../state/app"
import { usePage } from "../../hooks"
import { Accounts } from "../../config/accounts"
import PresetSelector from "./PresetSelector"
import ChainSelector from "./ChainSelector"
import { getStyles } from "./styles"
import AccountSelector from "./AccountSelector"
import PoolSelector from "./PoolSelector"
import DateRangeSelector from "./DateRangeSelector"
import CoinSelector from "./CoinSelector"
import ExchangeSelector from "./ExchangeSelector"
import ExcludedAccountSelector from "./ExcludedAccountSelector"
import ExcludedCoinSelector from "./ExcludedCoinSelector"

function App() {
  const styles = getStyles()
  const context = useWeb3React<Web3Provider>()
  const { connector, account, activate, deactivate } = context
  const [activatingConnector, setActivatingConnector] = useState()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [, setPresets] = useRecoilState(selectedPresetsState)!
  const page = usePage()

  // console.log("----- page", page)

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [connector, activatingConnector]);

  const handleConnect = (type: ConnectorTypes = ConnectorTypes.Injected) => {
    if ([ConnectorTypes.Injected].includes(type)) {
      const currentConnector = getConnector(type); 
      setActivatingConnector(currentConnector);
      activate(currentConnector);
    } else {

    }
    closeMenu();
  }

  const handleDisconnect = () => {
    deactivate();
    closeMenu();
  }

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  //const params = useParams()
  //const location = useLocation()
  //console.log("---------- params, location", params, location)

  const { preset: paramPreset } = useParams()
  const validPresets: string[] = Object.values(Preset)
  const authAccounts = Accounts.filter(e => e.auth)
  const isAuthenticated = account && authAccounts.map(e => e.address).includes(account)

  useEffect(() => {
    // console.log("----- scope changed", scope, paramScope, validScopes);
    if (paramPreset && validPresets.includes(paramPreset)) {
      setPresets([paramPreset as Preset])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramPreset]);


  const closeMenu = () => {
    setAnchorEl(null);
  }


  return (
    <Fragment>
      <Global styles={styles.global} />
      <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
        <Stack direction="row">
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="menu"
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            {isAuthenticated
            ? <MenuItem onClick={() => handleDisconnect()}>Logout</MenuItem>
            : <MenuItem onClick={() => handleConnect()}>Login</MenuItem>
            }
          </Menu>

          <PresetSelector />
          <ChainSelector />
          <AccountSelector />
          <ExcludedAccountSelector />
          <PoolSelector />
          <CoinSelector />
          <ExcludedCoinSelector />
          <ExchangeSelector />
          <DateRangeSelector />

        </Stack>
        <Box>
          {isAuthenticated && <Typography color="inherit">{account}</Typography>}
        </Box>
      </Toolbar>
      {isAuthenticated && <Box css={styles.links}>
        <Link href={"/"} className={page === Page.DASHBOARD ? "active" : ""}>Dashboard</Link>
        <Link href={"/transactions"} className={page === Page.TRANSACTIONS ? "active" : ""}>Transactions</Link>
        <Link href={"/farms"} className={page === Page.FARMS ? "active" : ""}>Farms</Link>
        <Link href={"/staking"} className={page === Page.STAKING ? "active" : ""}>Staking</Link>
      </Box>}

      {isAuthenticated && <Outlet />}

      {isAuthenticated && <BalanceFetcher />}
      {/*<DebugObserver />*/}
      {isAuthenticated && <DebugButton />}
      {!isAuthenticated && 
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography>Not authorized</Typography>
        </Box>
      }
    </Fragment>
  );
}

export default App;

/*

          isAuthenticated && scope && <Tabs value={scope}>
            <Tab label="MCC" value={Preset.MCC} to="/mcc" component={NavLink} />
            <Tab label="Personal" value={Preset.PERSONAL} to="/personal" component={NavLink} />
            <Tab label="Storage" value={Preset.STORAGE} to="/storage" component={NavLink} />
            <Tab label="NextGen" value={Preset.NEXT_GEN} to="/nextgen" component={NavLink} />
            <Tab label="SXN" value={Preset.SXN} to="/sxn" component={NavLink} />
            <Tab label="Toronto" value={Preset.TORONTO} to="/toronto" component={NavLink} />
          </Tabs>


      <Stack direction="row" spacing={2} padding={2}>
        <Stack direction="column">

        </Stack>
        <Stack spacing={2}>
          <Pairs />
        </Stack>
      </Stack>


      <Container sx={{ width: "100%" }}>
      </Container>

        {
          account ? 
          <Fragment>
            <Typography color="inherit">{account}</Typography>
            <Button onClick={handleDisconnect}>Logout</Button>
          </Fragment> : 
          <Button onClick={() => handleConnect()}>Login</Button>
        }


        <RadioGroup
          row
          name="scope"
          value={scope}
          onChange={e => setScope(e.target.value as Scope)}
        >
          <FormControlLabel
            value={Scope.MCC}
            control={<Radio />}
            label="MCC"
          />
          <FormControlLabel
            value={Scope.PERSONAL}
            control={<Radio />}
            label="Personal"
          />
          <FormControlLabel
            value={Scope.STORAGE}
            control={<Radio />}
            label="Storage"
          />
          <FormControlLabel
            value={Scope.NEXT_GEN}
            control={<Radio />}
            label="NextGen"
          />
        </RadioGroup>


*/
