import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'
import { Accounts } from "../config/accounts";
import { LPs } from "../config/assets";
import { Address, Chain, Coin, Exchange, Preset } from "../config/types"

export interface IScope {
  presets: Preset[];
  chains: Chain[];
  accounts: Address[];
  pools: Address[];
  coins: Coin[];
  excludedCoins: Coin[];
  exchanges: Exchange[];
  startDate: number | null;
  endDate: number | null;
}

export interface INamedScopes {
  [scope: string]: IScope;
} 

export const emptyScope: IScope = {
  presets: [],
  chains: [],
  accounts: [],
  pools: [],
  coins: [],
  excludedCoins: [],
  exchanges: [],
  startDate: null,
  endDate: null,
}

const { persistAtom } = recoilPersist({
  key: "coins"
})

export const selectedPresetsState = atom<Preset[]>({
  key: 'SelectedPresets',
  default: [Preset.PERSONAL],
  effects_UNSTABLE: [persistAtom],
})

export const selectedCoinsState = atom<Coin[]>({
  key: 'SelectedCoins',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const excludedCoinsState = atom<Coin[]>({
  key: 'ExcludedCoins',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const selectedPoolsState = atom<Address[]>({
  key: 'SelectedPools',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const selectedAccountsState = atom<Address[]>({
  key: 'SelectedAccounts',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const excludedAccountsState = atom<Address[]>({
  key: 'ExcludedAccounts',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const selectedChainsState = atom<Chain[]>({
  key: 'SelectedChains',
  default: [Chain.MAINNET, Chain.ARBITRUM, Chain.POLYGON, Chain.SXN, Chain.BITCOIN],
  effects_UNSTABLE: [persistAtom],
})

export const selectedExchangesState = atom<Exchange[]>({
  key: 'SelectedExchanges',
  default: [Exchange.SHARKSWAP, Exchange.PARASWAP],
  effects_UNSTABLE: [persistAtom],
})

export const selectedStartDateState = atom<number | null>({
  key: "SelectedStartDate",
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const selectedEndDateState = atom<number | null>({
  key: "SelectedEndDate",
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const coinOptions: Coin[] = Object.values(Coin).filter(e => e !== Coin.DEPRECATED && !e.includes("_"))

export const scopeSelector = selector<IScope>({
  key: 'ScopeSelector',
  get: ({ get }): IScope => {
    const presets = get(selectedPresetsState)
    const chains = get(selectedChainsState)
    const selectedAccounts = get(selectedAccountsState)
    const excludedAccounts = get(excludedAccountsState)
    const selectedPools = get(selectedPoolsState)
    const selectedCoins = get(selectedCoinsState)
    const excludedCoins = get(excludedCoinsState)
    const selectedExchanges = get(selectedExchangesState)
    const selectedStartDate = get(selectedStartDateState)
    const selectedEndDate = get(selectedEndDateState)

    return ({
      presets,
      chains,
      accounts: selectedAccounts.length 
        ? selectedAccounts 
        : Accounts.filter(e => (
          e.presets.some(preset => presets.includes(preset)) && !excludedAccounts.includes(e.address)
        )).map(e => e.address),
      pools: selectedPools.length ? selectedPools : LPs.filter(e => chains.includes(e.chainId)).map(e => e.address!),
      coins: selectedCoins.length ? selectedCoins : coinOptions,
      excludedCoins,
      exchanges: selectedExchanges.length ? selectedExchanges : Object.values(Exchange),
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    })
  }
})

// -----------------------------------------------------------
export interface IScopeState {
  presets?: Preset[];
  chains?: Chain[];
  selectedAccounts?: Address[];
  excludedAccounts?: Address[];
  selectedPools?: Address[];
  selectedCoins?: Coin[];
  excludedCoins?: Coin[];
  selectedExchanges?: Exchange[];
  selectedStartDate?: number | null;
  selectedEndDate?: number | null;
}



export const currentScopeState = atom<IScopeState>({
  key: "CurrentScope",
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const currentScopeSelector = selector<IScope>({
  key: "CurrentScopeSelector",
  get: ({ get }) => {
    const scope = get(currentScopeState)
    const mappedScope: IScope = {
      presets: scope.presets || [],
      chains: scope.chains || [],
      accounts: scope.selectedAccounts?.length 
        ? scope.selectedAccounts 
        : Accounts.filter(e => (
          e.presets.some(preset => (scope.presets || []).includes(preset)) && !(scope.excludedAccounts || []).includes(e.address)
        )).map(e => e.address),
      pools: scope.selectedPools?.length ? scope.selectedPools : LPs.filter(e => (scope.chains || []).includes(e.chainId)).map(e => e.address!),
      coins: scope.selectedCoins?.length ? scope.selectedCoins : coinOptions,
      excludedCoins: scope.excludedCoins || [],
      exchanges: scope.selectedExchanges?.length ? scope.selectedExchanges : Object.values(Exchange),
      startDate: scope.selectedStartDate || null,
      endDate: scope.selectedEndDate || null,
    }
    return mappedScope
  }
  /*
  set: ({ set, get }, newValue) => {
    const value = get(currentScopeState)
    set(currentScopeState, { ...value, ...newValue })
  }
  */
})

export const namedScopesState = atom<INamedScopes>({
  key: 'NamedScopes',
  default: { current: emptyScope },
  effects_UNSTABLE: [persistAtom],
})

export const namedScopeSelector = selector<INamedScopes>({
  key: "NamedScopesSelector",
  get: ({ get }) => get(namedScopesState),
  set: ({ set, get }, newValue) => {
    const value = get(namedScopesState)
    set(namedScopesState, { ...value, ...newValue })
  }
})

// -----------------------------------------------------------

/*
export enum Loading {
  BALANCES = "BALANCES",
  RATES = "RATES"
}

export const loadingState = atom<Loading[]>({
  key: 'CurrentLoading',
  default: []
});

//export const date_RangeState = atom<IDate_Range>({
//  key: 'SelectedDate_Range',
//  default: {
//    startDate: null,
//    endDate: null,
//  },
//  effects_UNSTABLE: [persistAtom],
//})



*/
