import { Address, AssetId, Chain } from "../../config/types";
import { IScope } from "../../state/app";
import { useOldStaking } from "./oldStaking";
import { useSxStaking } from "./sxStaking";

export const STAKING_ACCOUNTS = [
  // prior to Sep 2023: staked at foundation validator
  // "0x47378BFb91FcdD9a377D44C13f7Cff185129b6d5",
  // "0x6aECa9A564C210BAb9D40bD6C8A41683d8D9cDBa",
  // "0x6a0f764ab795A48Bcdc9068a52A1969aFFb328A5",

  // starting Sep 26, 2023: validator + delegators
  "0xEbB9EE54D6B5E115f0411e90826D1e052a382a2d",
  "0xc829930ADA40F27515f2f986167bF9f12f4E2c35",
  "0x786EfA9Fa71f6a9D29793a37c4A63F34a93d75f2",
  "0x8F374b00345Ae0EbaA27e34A7354D2F9fA2DE27D",
]
export const OLD_STAKING_ACCOUNTS = [
  "0x6aECa9A564C210BAb9D40bD6C8A41683d8D9cDBa",
  "0x47378BFb91FcdD9a377D44C13f7Cff185129b6d5",
  //"0xcB418932376f4c9B12bA2078A7b4cf85748444bb", // locked purchase plan
]

export interface IStakingHarvestEvent {
  timestamp: number;
  chainId: Chain;
  txHash: string;
  accountAddress: Address;

  asset: AssetId;
  tokenAddress: Address;
  amount: number;
  // amountUSD?: number;
}

export interface IStakingHarvestSummary {
  [asset: string]: {
    tokenAddress: string;
    amount: number;
    amountUSD: number;
    currentValueUSD: number;
  }
}

export interface IStakingHarvestTx {
  timestamp: number;
  // block: number;
  chainId: Chain;
  txHash: string;
  accountAddress: Address;
  amounts: {
    [asset: string]: number;
  }
  amountsUSD: {
    [asset: string]: number;
  }
  currentValuesUSD: {
    [asset: string]: number;
  }
}

export function useStakingData(shouldFetch: boolean, scope: IScope) {
  const { data: sxrStakingData } = useSxStaking(shouldFetch, scope, Chain.SXR);
  const { data: sxnStakingData } = useSxStaking(shouldFetch && false, scope, Chain.SXN);
  const { data: sxnOldStakingData } = useOldStaking(shouldFetch && false, scope, Chain.SXN);
  const { data: polygonOldStakingData } = useOldStaking(shouldFetch && false, scope, Chain.POLYGON);

  let txs: IStakingHarvestTx[] = []
  let summary: IStakingHarvestSummary = {}

  txs = [
    ...sxrStakingData?.txs || [], 
    ...sxnStakingData?.txs || [], 
    ...sxnOldStakingData?.txs || [], 
    ...polygonOldStakingData?.txs || []
  ]
  txs.sort((a, b) => b.timestamp - a.timestamp)

  for (let summ of [sxrStakingData?.summary, sxnStakingData?.summary, sxnOldStakingData?.summary, polygonOldStakingData?.summary]) {
    if (summ && Object.keys(summ)) {
      for (let coin of Object.keys(summ)) {
        if (!summary[coin]) {
          summary[coin] = {
            tokenAddress: summ[coin].tokenAddress,
            amount: 0,
            amountUSD: 0,
            currentValueUSD: 0
          }
        }
        summary[coin].amount += summ[coin].amount
        summary[coin].amountUSD += summ[coin].amountUSD
        summary[coin].currentValueUSD += summ[coin].currentValueUSD
      }  
    }
  }

  return { data: { txs, summary }}
}
