/** @jsxImportSource @emotion/react */
import { Box, Checkbox, Chip, Input, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { useRecoilState, useRecoilValue } from "recoil"
import { LPs } from "../../config/assets"
import { Address } from "../../config/types"
import { selectedChainsState, selectedPoolsState } from "../../state/app"
import { getStyles } from "./styles"

function PoolSelector() {
  const styles = getStyles()
  const [pools, setPools] = useRecoilState(selectedPoolsState)
  const chains = useRecoilValue(selectedChainsState)
  const allPools = LPs
  const poolsOnSelectedChains = LPs.filter(e => chains.includes(e.chainId)).map(e => e.address)

  const handleChange = (event: SelectChangeEvent<Address[]>) => {
    const { target: { value }} = event;
    setPools(value as Address[]);
  };

  return (
    <Select
      multiple
      displayEmpty
      value={pools}
      onChange={handleChange}
      input={<Input disableUnderline />}
      renderValue={(selected) => (
        <Box css={styles.selector} display="flex">
          <Chip label={`${selected.length} pool`} size="small" />       
        </Box>
      )}
    >
      {allPools.map(({ id, address }) => (
        <MenuItem key={address} value={address}>
          <Checkbox checked={pools.includes(address!)} />
          <Box>
            <Typography variant="body2">{`${id}`}</Typography>
            <Typography 
              className={poolsOnSelectedChains.includes(address) ? "active" : ""}
              css={styles.account}
            >
              {address}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}

export default PoolSelector
