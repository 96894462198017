import { Stack } from "@mui/material";
import FarmingSummary from "./FarmingSummary";
import FarmingTransactions from "./FarmingTransactions";
import { usePage } from "../../hooks";
import { useRecoilValue } from "recoil";
import { scopeSelector } from "../../state/app";
import { useFarmingTxs } from "../../services/farming";
import { Page } from "../../config/types";


function Farming() {
  const page = usePage();
  console.log("-------- farming page", page);
  const scope = useRecoilValue(scopeSelector);
  // console.log("-------- staking scope", scope);

  const shouldFetch = page === Page.FARMS;
  const { data } = useFarmingTxs(shouldFetch, scope);
  const txs = data?.txs || []
  const tokenMap = data?.tokens || {}
  // const summary = data?.summary

  const order = ["USDC", "WETH", "WMATIC", "WSX", "SHARK", "LFI", "SLP"]
  const tokens = Object.keys(tokenMap).map(key => ({
    ...tokenMap[key],
    address: key,
  }))
  tokens.sort((a, b) => order.indexOf(a.symbol) - order.indexOf(b.symbol))

  return (
    <Stack direction="row" spacing={2} padding={2}>
      <FarmingTransactions txs={txs} tokens={tokens} />
      <FarmingSummary tokens={tokens} />
    </Stack>
  )
}

export default Farming
