import { Coin } from "./types";

export interface IFormatterConfig {
  prefix?: string;
  pos?: number;
}

export const FormatterConfigs: {
  [coin: string]: IFormatterConfig
} = {
  [Coin.ETH]: {
    prefix: "Ξ",
    pos: 3
  },
  [Coin.BTC]: {
    prefix: "₿",
    pos: 4
  },
  [Coin.WMATIC_SX_ALP]: {
    prefix: "ALP ",
  },
  [Coin.WETH_DAI_SLP]: {
    prefix: "SLP ",
  },
  [Coin.OHM_WETH_SLP]: {
    prefix: "SLP ",
    pos: 6
  },

};

/*

  [Coin.WETH_SX_SLP]: {
    prefix: "SLP ",
  },

  [AssetGroupId.DAI_WETH_QLP]: {
    prefix: "QLP ",
  },

*/
