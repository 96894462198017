import { AssetId, Chain, Coin, Exchange, IRatePair, Preset, RateType } from "../types";
import UniswapV3Pool from "../../config/abi/UniswapV3Pool.json";

const ratePairs: IRatePair[] = [
  {
    id: "ARB:ETH:ARBITRUM",
    type: RateType.DERIVED_V3,
    query: Coin.ARB,
    base: Coin.ETH,
    token1AsBase: false,
    chainId: Chain.ARBITRUM,
    platformId: Exchange.UNISWAP,
    address: "0xC6F780497A95e246EB9449f5e4770916DCd6396A",
    abi: UniswapV3Pool,
    token0: AssetId.WETH,
    token1: AssetId.ARB,
    presets: [Preset.PERSONAL, Preset.NEXT_GEN]
  },
  {
    id: "ETH:SX",
    type: RateType.DERIVED_V3,
    query: Coin.ETH,
    base: Coin.SX,
    token1AsBase: true,
    chainId: Chain.ARBITRUM,
    platformId: Exchange.SUSHISWAP,
    address: "0xBC7074733D1dBaEdFB31F031AEb3bFd2fDFd98Ac",
    abi: UniswapV3Pool,
    token0: AssetId.SX,
    token1: AssetId.WETH,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },

];

export default ratePairs
