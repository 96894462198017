import { InjectedConnector } from '@web3-react/injected-connector';
import { Chain } from '../config/types';

let connectors: any = {};

export enum ConnectorTypes {
  Injected = 'Injected',
}

export function getConnector(type: ConnectorTypes, params: any = {}) {
  if (!connectors[type]) {
    switch (type) {
      case ConnectorTypes.Injected:
        connectors[type] = new InjectedConnector({ 
          supportedChainIds: Object.values(Chain) as any 
        });
        break;
    }     
  }
  return connectors[type];
}
