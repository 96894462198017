export const ChartColors = [
  '#4dc9f6',
  '#f67019',
  '#acc236',
  '#537bc4',
  '#f53794',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];


/*


  'rgba(255, 99, 132, 0.7)',
  'rgba(54, 162, 235, 0.7)',
  'rgba(255, 159, 64, 0.7)',
  'rgba(75, 192, 192, 0.7)',
  'rgba(255, 206, 86, 0.7)',
  'rgba(153, 102, 255, 0.7)',


*/