/** @jsxImportSource @emotion/react */
import { Box, Checkbox, Chip, Input, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useRecoilState } from "recoil";
import { Chain } from "../../config/types";
import { selectedChainsState } from "../../state/app";
import { getStyles } from "./styles";

function ChainSelector() {
  const [chains, setChains] = useRecoilState(selectedChainsState)
  const availableChains = Object.values(Chain).filter((k: any) => typeof k === "number");
  const styles = getStyles();

  const handleChange = (event: SelectChangeEvent<Chain[]>) => {
    const { target: { value }} = event;
    setChains(value as Chain[]);
  };

  return (
    <Select
      multiple
      displayEmpty
      value={chains}
      onChange={handleChange}
      input={<Input disableUnderline />}
      renderValue={(selected) => (
        <Box css={styles.selector} display="flex">
          <Chip label={selected.length ? selected.map((v: any) => Chain[v][0].toLowerCase()).join("") : "none"} size="small" />
        </Box>
      )}
    >
      {availableChains.map((value: any) => (
        <MenuItem
          key={value}
          value={value}
        >
          <Checkbox checked={chains.includes(value)} />
          {Chain[value].toLowerCase()}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ChainSelector
