export enum WalletTypes {
  METAMASK = "METAMASK",
  FORTMATIC = "FORTMATIC",
  LEDGER_ETH = "LEDGER_ETH",
  LEDGER_BTC = "LEDGER_BTC",
  PAPER_ETH = "PAPER_ETH"
}

export enum WalletIds {
  CHROME_MM = "CHROME_MM",
  FIREFOX_MM = "FIREFOX_MM",
  BRAVE_MM = "BRAVE_MM",
  IPHONE_MM = "IPHONE_MM",
  ICLOUD_FM = "ICLOUD_FM",
  NEXTGEN_FM = "NEXTGEN_FM",
  NANO_ETH = "NANO_ETH",
  NANO_BTC = "NANO_BTC",
  NEXTGEN_TEST = "NEXTGEN_TEST",
  NEXTGEN_ADMIN = "NEXTGEN_ADMIN",
  NFTS_LIZA = "NFTS_LIZA",
  TORONTO_TEST = "TORONTO_TEST",
  LOCKED_WALLET = "LOCKED_WALLET",
  PAPER = "PAPER",
}

export interface IWallet {
  id: string;
  type: WalletTypes
}

export const Wallets: IWallet[] = [
  { 
    id: WalletIds.CHROME_MM,
    type: WalletTypes.METAMASK
  },
  { 
    id: WalletIds.LOCKED_WALLET,
    type: WalletTypes.PAPER_ETH
  },
  { 
    id: WalletIds.FIREFOX_MM,
    type: WalletTypes.METAMASK
  },
  { 
    id: WalletIds.BRAVE_MM,
    type: WalletTypes.METAMASK
  },
  { 
    id: WalletIds.IPHONE_MM,
    type: WalletTypes.METAMASK
  },
  { 
    id: WalletIds.ICLOUD_FM,
    type: WalletTypes.FORTMATIC
  },
  { 
    id: WalletIds.NEXTGEN_FM,
    type: WalletTypes.FORTMATIC
  },
  { 
    id: WalletIds.PAPER,
    type: WalletTypes.PAPER_ETH
  },
  { 
    id: WalletIds.NANO_ETH,
    type: WalletTypes.LEDGER_ETH
  },
  { 
    id: WalletIds.NANO_BTC,
    type: WalletTypes.LEDGER_BTC
  },
  { 
    id: WalletIds.NEXTGEN_TEST,
    type: WalletTypes.PAPER_ETH
  },
  { 
    id: WalletIds.NEXTGEN_ADMIN,
    type: WalletTypes.PAPER_ETH
  },
  { 
    id: WalletIds.NFTS_LIZA,
    type: WalletTypes.PAPER_ETH
  },
  { 
    id: WalletIds.TORONTO_TEST,
    type: WalletTypes.PAPER_ETH
  },
];

export enum WalletStatus {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  FETCHING = "fetching"
}
