import { createRoot } from "react-dom/client";
import App from './features/app/App';
import { RecoilRoot } from 'recoil';
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './features/dashboard/Dashboard';
import Transactions from "./features/transactions/Transactions";
import Staking from "./features/staking";
import Farming from "./features/farms";

// import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    ethereum: any;
    web3: any;
    state: any;
  }
}

function getLibrary(provider: any, connector: any) {
  return new Web3Provider(provider);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Dashboard />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="farms" element={<Farming />} />
            <Route path="staking" element={<Staking />} />
            {/*<Route path=":scope">
              <Route index element={<Dashboard />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="farms" element={<Farms />} />
            </Route>*/}
          </Route>
        </Routes>
      </BrowserRouter>  
    </RecoilRoot>
  </Web3ReactProvider>  
);

/*


              <Route index element={<Dashboard />} />
              <Route path="pairs" element={<Pairs />} />
              <Route path="charts" element={<Charts />} />

          <Route path={Scope.MCC} element={<App />} />
          <Route path={Scope.PERSONAL} element={<App />} />
          <Route path={Scope.STORAGE} element={<App />} />
          <Route path={Scope.NEXT_GEN} element={<App />} />            

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes >
            <Route path={Scope.MCC} element={<App />} />
            <Route path={Scope.PERSONAL} element={<App />} />
            <Route path={Scope.STORAGE} element={<App />} />
            <Route path={Scope.NEXT_GEN} element={<App />} />            
          </Routes>
        </BrowserRouter>  
      </RecoilRoot>
    </Web3ReactProvider>  
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
