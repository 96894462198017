import { Contract } from "ethers";
import { Assets, LPs } from "../config/assets";
import { Coin } from "../config/types";
import { ILiquidityPairAtom } from "../state/liquidityPairs";
import { fromWei } from "../utils";
import { getRpcProvider } from "./providers";

export async function getLiquidityPair(assetUniqueId: string): Promise<ILiquidityPairAtom | undefined> {
  try {
    const asset = LPs.find(e => e.id === assetUniqueId)!;
    const { coin, relatedAssetIds, chainId, address, abi, decimals, custom } = asset; 

    // console.log(`-------- getLiquidityPair ${assetUniqueId} pair`, pair)

    const meta0 = Assets.find(meta => meta.assetId === relatedAssetIds![0] && meta.chainId === chainId)!;
    const meta1 = Assets.find(meta => meta.assetId === relatedAssetIds![1] && meta.chainId === chainId)!;

    // console.log(`-------- getLiquidityPair ${assetUniqueId} meta`, meta0, meta1)

    const provider = getRpcProvider(chainId);
    const contract = new Contract(address!, abi, provider);

    // console.log(`-------- getLiquidityPair ${assetUniqueId} contract`, contract)

    const totalSupply = await contract.totalSupply();

    // console.log(`-------- getLiquidityPair ${assetUniqueId} totalSupply`, totalSupply.toString())

    let reservesArray;
    let decimalsArray;

    if (coin === Coin.SX_USDC_ILP) {
      const exchange = new Contract(custom.exchangeAddress, custom.exchangeAbi, provider);
      const pool = await exchange.loadLiquidityPoolByAssetAddresses(
        meta0.address,
        meta1.address
      );
      // console.log("------ SX_USDC_ILP pool", pool);
      reservesArray = [pool.quoteAssetReserveInPips, pool.baseAssetReserveInPips];
      decimalsArray = [8, 8];
    } else {
      reservesArray = await contract.getReserves();
      decimalsArray = [meta0.decimals, meta1.decimals]
    }

    // const [reserve0, reserve1] = await contract.getReserves();

    // console.log(`-------- getLiquidityPair ${assetUniqueId} reserves`, reservesArray[0].toString(), reservesArray[1].toString())


    const data = {
      totalSupply: fromWei(totalSupply, decimals), 
      reserve0: fromWei(reservesArray[0], decimalsArray[0]), 
      reserve1: fromWei(reservesArray[1], decimalsArray[1])
    }
    return data;

  }  catch (err) {
    console.log(`-------- getLiquidityPair ${assetUniqueId}`, err);
  } 
}

/*
export async function getPairs(): Promise<IPairsState | undefined> {
  try {
    const results = await Promise.all(
      LiquidityPairs.map(async pair => {
        const meta0 = Assets.find(meta => meta.assetId === pair.token0 && meta.chainId === pair.chainId)!;
        const meta1 = Assets.find(meta => meta.assetId === pair.token1 && meta.chainId === pair.chainId)!;

        const provider = getRpcProvider(pair.chainId);
        const contract = new Contract(pair.address, pair.abi, provider);
        const totalSupply = await contract.totalSupply();
        const [reserve0, reserve1] = await contract.getReserves();

        return [
          pair.id, 
          fromWei(totalSupply, pair.decimals), 
          fromWei(reserve0, meta0.decimals), 
          fromWei(reserve1, meta1.decimals)
        ];
      })
    );
    const pairs: any = results.reduce((acc: IPairsState, res: any) => {
      const [id, totalSupply, reserve0, reserve1] = res;
      if (id) {
        acc[id] = {
          totalSupply, 
          reserve0, 
          reserve1
        };
      }
      return acc;
    }, {});

    return pairs;
  }  catch (err) {
    console.log("-------- getPairs", err);
  } 
}
*/
