import { Contract } from "ethers";
import { Contract as ContractEthcall } from 'ethcall';
import { IAsset } from "../config/types";
import { getRpcProvider } from "./providers";


let contracts: {
  [id: string]: Contract;
} = {};

let contractsEthcall: {
  [id: string]: ContractEthcall;
} = {};

export function getContract(meta: IAsset) {
  const { id, chainId,  address, abi } = meta;
  const provider = getRpcProvider(chainId);
  if (!contracts[id]) {
    contracts[id] = new Contract(address!, abi, provider);
  }

  return contracts[id];
}

export function getContractEthcall(meta: IAsset) {
  const { id, address, abi } = meta;
  if (!contractsEthcall[id]) {
    contractsEthcall[id] = new ContractEthcall(address!, abi);
  }

  return contractsEthcall[id];
}
