import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin, IFarm, ILiquidityPair, IToken } from "../types"
import MaticWETH from "../abi/MaticWETH.json";
// import Staking from "../abi/Staking.json";
import UChildAdministrableERC20 from "../abi/UChildAdministrableERC20.json";
import UChildDAI from "../abi/UChildDAI.json";
import UChildERC20 from "../abi/UChildERC20.json";
import WMATIC from "../abi/WMATIC.json";
//import UniswapV2Pair from "../abi/UniswapV2Pair.json";
//import MiniChef from "../abi/MiniChef.json";

const tokens: IToken[] = [
  {
    id: `${AssetId.MATIC}:${Chain.POLYGON}`,
    assetId: AssetId.MATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.NATIVE,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    decimals: 18,
  },
  {
    id: `${AssetId.WMATIC}:${Chain.POLYGON}`,
    assetId: AssetId.WMATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    decimals: 18,
    abi: WMATIC
  },
  {
    id: `${AssetId.AM_MATIC}:${Chain.POLYGON}`,
    assetId: AssetId.AM_MATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.DEPOSIT,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
    decimals: 18,
    abi: UChildERC20,
    inactive: true,
  },
  {
    id: `${AssetId.VDM_MATIC}:${Chain.POLYGON}`,
    assetId: AssetId.VDM_MATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.BORROW,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x59e8e9100cbfcbcbadf86b9279fa61526bbb8765",
    decimals: 18,
    abi: UChildDAI,
    inactive: true,
  },
  {
    id: `${AssetId.A_MATIC}:${Chain.POLYGON}`,
    assetId: AssetId.A_MATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.BORROW,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x4a1c3aD6Ed28a636ee1751C69071f6be75DEb8B8",
    decimals: 18,
    abi: UChildDAI,
    inactive: true,
  },

  {
    id: `${AssetId.VDM_USDC}:${Chain.POLYGON}`,
    assetId: AssetId.VDM_USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.BORROW,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.POLYGON,
    address: "0xFCCf3cAbbe80101232d343252614b6A3eE81C989", // "0x248960a9d75edfa3de94f7193eae3161eb349a12",
    decimals: 6,
    abi: UChildDAI,
    inactive: true,
  },
  {
    id: `${AssetId.WETH}:${Chain.POLYGON}`,
    assetId: AssetId.WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.POLYGON,
    address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    decimals: 18,
    abi: MaticWETH
  },
  {
    id: `${AssetId.A_WETH}:${Chain.POLYGON}`,
    assetId: AssetId.A_WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.BORROW,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.POLYGON,
    address: "0x0c84331e39d6658Cd6e6b9ba04736cC4c4734351", 
    // address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
    decimals: 18,
    abi: UChildDAI,
    inactive: true,
  },
  {
    id: `${AssetId.AM_WETH}:${Chain.POLYGON}`,
    assetId: AssetId.AM_WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.DEPOSIT,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.POLYGON,
    // address: "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
    decimals: 18,
    abi: MaticWETH,
    inactive: true,
  },
  
  {
    id: `${AssetId.DAI}:${Chain.POLYGON}`,
    assetId: AssetId.DAI,
    coin: Coin.DAI,
    groupId: AssetGroupId.DAI,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.POLYGON,
    address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    decimals: 18,
    abi: UChildDAI,
    inactive: true,
  },
  /*
  {
    id: `${AssetId.AM_DAI}:${Chain.POLYGON}`,
    assetId: AssetId.AM_DAI,
    coin: Coin.DAI,
    groupId: AssetGroupId.DAI,
    type: AssetType.DEPOSIT,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.POLYGON,
    address: "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee", //"0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    decimals: 18,
    abi: UChildERC20,
  },
  */
  {
    // Native USDC issued by Circle (new USDC on Polygon)
    id: `${AssetId.USDC}:${Chain.POLYGON}`,
    assetId: AssetId.USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.POLYGON,
    address: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    decimals: 6,
    abi: UChildAdministrableERC20
  },
  {
    // USDC bridged from Ethereum (old USDC on Polygon)
    id: `${AssetId.USDC_E}:${Chain.POLYGON}`,
    assetId: AssetId.USDC_E,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.POLYGON,
    address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    decimals: 6,
    abi: UChildAdministrableERC20
  },
  {
    id: `${AssetId.AM_USDC}:${Chain.POLYGON}`,
    assetId: AssetId.AM_USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.DEPOSIT,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.POLYGON,
    address: "0x625e7708f30ca75bfd92586e17077590c60eb4cd", // "0x1a13f4ca1d028320a707d99520abfefca3998b7f", v2
    decimals: 6,
    abi: UChildERC20,
    inactive: true,
  },
  {
    id: `${AssetId.WBTC}:${Chain.POLYGON}`,
    assetId: AssetId.WBTC,
    coin: Coin.BTC,
    groupId: AssetGroupId.BTC,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.POLYGON,
    address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    decimals: 8,
    abi: UChildERC20
  },
  {
    id: `${AssetId.AM_WBTC}:${Chain.POLYGON}`,
    assetId: AssetId.AM_WBTC,
    coin: Coin.BTC,
    groupId: AssetGroupId.BTC,
    type: AssetType.DEPOSIT,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.POLYGON,
    address: "0x078f358208685046a11C85e8ad32895DED33A249", // "0x5c2ed810328349100a66b82b78a1791b101c9d61", v2
    decimals: 8,
    abi: UChildERC20,
    inactive: true,
  },
  {
    id: `${AssetId.SX}:${Chain.POLYGON}`,
    assetId: AssetId.SX,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    decimals: 18,
    abi: UChildERC20
  },
  /*
  {
    id: `${AssetId.SX_STAKED}:${Chain.POLYGON}`,
    assetId: AssetId.SX_STAKED,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_STAKED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0xcc96F1187682738eFa63E0fd051AbbB2dBF87FAe",
    decimals: 18,
    abi: Staking,
    // custom: true,
  },
  {
    id: `${AssetId.SX_PENDING}:${Chain.POLYGON}`,
    assetId: AssetId.SX_PENDING,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_PENDING,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0xcc96F1187682738eFa63E0fd051AbbB2dBF87FAe",
    decimals: 18,
    abi: Staking,
    // custom: true,
  },
  */

  {
    id: `${AssetId.LFI}:${Chain.POLYGON}`,
    assetId: AssetId.LFI,
    coin: Coin.LFI,
    groupId: AssetGroupId.LFI,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0xCa7BF3C514d412AC12D10Eff302301A81153F557",
    decimals: 18,
    abi: UChildERC20,
    inactive: true,
    // custom: true,
  },
  {
    id: `${AssetId.IDEX}:${Chain.POLYGON}`,
    assetId: AssetId.IDEX,
    coin: Coin.IDEX,
    groupId: AssetGroupId.IDEX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x9Cb74C8032b007466865f060ad2c46145d45553D", // "0x01aCf8ED00DB1F7B4c438319Fe35E1D1092261b7",
    decimals: 18,
    abi: UChildERC20,
    inactive: true,
  },
  {
    id: `${AssetId.DINO}:${Chain.POLYGON}`,
    assetId: AssetId.DINO,
    coin: Coin.DINO,
    groupId: AssetGroupId.DINO,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0xAa9654BECca45B5BDFA5ac646c939C62b527D394",
    decimals: 18,
    abi: UChildERC20,
    inactive: true,
  },

]

const lps: ILiquidityPair[] = [ 
  /*
  {
    id: `${AssetId.WETH_SX_SLP}:${Chain.POLYGON}`,
    assetId: AssetId.WETH_SX_SLP,
    coin: Coin.WETH_SX_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x1bf9805b40a5f69c7d0f9e5d1ab718642203c652",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.SX, AssetId.WETH],
    custom: {
      deposits: [] // 149004.21, 20.4999
    },
  },
  */
]

const farms: IFarm[] = [
  /*
  {
    id: `${AssetId.WETH_SX_SLP_STAKED}:${Chain.POLYGON}`,
    assetId: AssetId.WETH_SX_SLP_STAKED,
    coin: Coin.WETH_SX_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x0769fd68dfb93167989c6f7254cd0d766fb2841f",
    decimals: 18,
    abi: MiniChef,
    relatedAssetIds: [AssetId.WETH_SX_SLP],
    pid: 52,
  },
  */
]

export { tokens, lps, farms }

  /*
  {
    id: `${AssetId.QUICK}:${Chain.POLYGON}`,
    assetId: AssetId.QUICK,
    coin: Coin.QUICK,
    groupId: AssetGroupId.QUICK,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    decimals: 18,
    abi: UChildERC20
  },
  {
    id: `${AssetId.CRYSTL}:${Chain.POLYGON}`,
    assetId: AssetId.CRYSTL,
    coin: Coin.CRYSTL,
    groupId: AssetGroupId.CRYSTL,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x76bf0c28e604cc3fe9967c83b3c3f31c213cfe64",
    decimals: 18,
    abi: UChildERC20
  },
  {
    id: `${AssetId.IDEX}:${Chain.POLYGON}`,
    assetId: AssetId.IDEX,
    coin: Coin.IDEX,
    groupId: AssetGroupId.IDEX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.POLYGON,
    address: "0x9cb74c8032b007466865f060ad2c46145d45553d", // "0x01aCf8ED00DB1F7B4c438319Fe35E1D1092261b7",
    decimals: 18,
    abi: UChildERC20
  },
  */

  /*  
  {
    id: `${AssetId.WMATIC_SX_ALP}:${Chain.POLYGON}`,
    assetId: AssetId.WMATIC_SX_ALP,
    coin: Coin.WMATIC_SX_ALP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0xf90331cb67f9ae85739f5aedfa93dec68b7ce32e",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.WMATIC, AssetId.SX],
  },
  {
    id: `${AssetId.SX_WMATIC_ALP_STAKED}:${Chain.POLYGON}`,
    assetId: AssetId.SX_WMATIC_ALP_STAKED,
    coin: Coin.WMATIC_SX_ALP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0xeBCC84D2A73f0c9E23066089C6C24F4629Ef1e6d",
    decimals: 18,
    abi: CrystalToken,
    custom: true,
    relatedAssetIds: [AssetId.WMATIC_SX_ALP],
    pid: 52,
  },

  {
    id: `${AssetId.SX_USDC_ILP}:${Chain.POLYGON}`,
    assetId: AssetId.SX_USDC_ILP,
    coin: Coin.SX_USDC_ILP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0xeeb67BCBf53B47bC51D7D2b435b4169c47578b21",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.SX, AssetId.USDC],
    custom: {
      exchangeAddress: "0x3253A7e75539EdaEb1Db608ce6Ef9AA1ac9126B6",
      exchangeAbi: IdexExchange,
      deposits: [],
    }
  },
  {
    id: `${AssetId.SX_USDC_ILP_STAKED}:${Chain.POLYGON}`,
    assetId: AssetId.SX_USDC_ILP_STAKED,
    coin: Coin.SX_USDC_ILP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0xB9C951c85C8646daFCDC0aD99D326C621aBBAdcE",
    decimals: 18,
    abi: IdexFarm,
    // custom: true,
    relatedAssetIds: [AssetId.SX_USDC_ILP],
    pid: 19,
  },
  */


  /*
  {
    id: `${AssetId.WETH_DAI_SLP}:${Chain.POLYGON}`,
    assetId: AssetId.WETH_DAI_SLP,
    coin: Coin.WETH_DAI_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x6ff62bfb8c12109e8000935a6de54dad83a4f39f",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.WETH, AssetId.DAI],
  },
  {
    id: `${AssetId.WETH_DAI_SLP_STAKED}:${Chain.POLYGON}`,
    assetId: AssetId.WETH_DAI_SLP_STAKED,
    coin: Coin.WETH_DAI_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
    decimals: 18,
    abi: MiniChef,
    custom: true,
    relatedAssetIds: [AssetId.WETH_DAI_SLP],
    pid: 5,
  },
  {
    id: `${AssetId.USDC_DAI_SLP}:${Chain.POLYGON}`,
    assetId: AssetId.USDC_DAI_SLP,
    coin: Coin.USDC_DAI_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0xcd578f016888b57f1b1e3f887f392f0159e26747",
    decimals: 18,
    abi: UniswapV2Pair,
    relatedAssetIds: [AssetId.USDC, AssetId.DAI],
  },
  {
    id: `${AssetId.USDC_DAI_SLP_STAKED}:${Chain.POLYGON}`,
    assetId: AssetId.USDC_DAI_SLP_STAKED,
    coin: Coin.USDC_DAI_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
    decimals: 18,
    abi: MiniChef,
    custom: true,
    relatedAssetIds: [AssetId.USDC_DAI_SLP],
    pid: 11,
  },
  */

  /*
// import QuickSwapStakingRewards from "../config/abi/QuickSwapStakingRewards.json";
  //DAI_WETH_QLP = "DAI_WETH_QLP",
  //DAI_WETH_QLP_STAKED = "DAI_WETH_QLP_STAKED",
  //DAI_WETH_QLP = "DAI_WETH_QLP",


  {
    id: AssetId.DAI_WETH_QLP,
    groupId: AssetGroupId.DAI_WETH_QLP,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x4a35582a710e1f4b2030a3f826da20bfb6703c09",
    decimals: 18,
    abi: UniswapV2Pair,
  },
  {
    id: AssetId.DAI_WETH_QLP_STAKED,
    groupId: AssetGroupId.DAI_WETH_QLP,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.POLYGON,
    address: "0x785AaCd49c1Aa3ca573F2a32Bb90030A205b8147",
    decimals: 18,
    abi: QuickSwapStakingRewards,
    custom: true,
  },

*/
