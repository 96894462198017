import { Provider } from "ethcall";
import { MULTICALL_ADDRESSES } from "../config/providers";
import { Chain } from "../config/types";
import { getStaticProvider } from "./providers";

export class ProviderMulticall extends Provider {
  /*
  constructor() {
    super()
  }
  */

  patch(chainId: Chain): void {
    const patches = MULTICALL_ADDRESSES[chainId]
    if (patches) {
      for (let key in patches) {
        (this as any)[key] = patches[key]
      }
    }
  }

}

export async function getMulticallProvider(chainId: Chain) {
  const provider = getStaticProvider(chainId);
  const providerMulticall: any = new ProviderMulticall()
  await providerMulticall.init(provider)
  providerMulticall.patch(chainId)
  return providerMulticall
}

