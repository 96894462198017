/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@mui/material";
import { useRecoilValueLoadable } from "recoil";
import { VALUE_BASE } from "../../config";
import { Assets } from "../../config/assets";
import { AssetType } from "../../config/types";
import { liquidityPairSelector } from "../../state/liquidityPairs";
import { rateQuery } from "../../state/rates";
import { balanceFormatter, lv, percentFormatter } from "../../utils";
import { getStyles as getAppStyles } from "../app/styles";

function getStyles() {
  return {
    itemContainer: css`
      width: 100%;
      padding-left: 0!important;
    `,
    tableContainer: css`
      th, td {
        padding: 5px 10px;
      }
    `,
    headerCell: css`
      text-align: right;
    `,
    balancesCell: css`
      text-align: right;
    `,
    fallback: css`
      padding: 20px;
      svg {
        color: lightgray;
      }
    `
  }
}

function LiquidityPair(params: { id: string }) {
  const { id } = params;
  const styles = getStyles();
  const lpValues = lv(useRecoilValueLoadable(liquidityPairSelector(id)));

  const { chainId, coin, totalSupply, reserve0, reserve1, myBalance, myShare, balance0, balance1, deposits, token0, token1 } = (lpValues || {}); //, totalValue, myValue, value0, value1
  const base = VALUE_BASE;

  const rate = lv(useRecoilValueLoadable(rateQuery(`${coin}:${base}`)));

  const meta0: any = Assets.find(meta => meta.assetId === token0 && meta.chainId === chainId) || {};
  const meta1: any = Assets.find(meta => meta.assetId === token1 && meta.chainId === chainId) || {};
  const rate0 = lv(useRecoilValueLoadable(rateQuery(`${meta0.coin}:${base}`)));
  const rate1 = lv(useRecoilValueLoadable(rateQuery(`${meta1.coin}:${base}`)));

  if (!lpValues || !rate0 || !rate1 || !rate) return null;

  const totalValue = totalSupply * rate;
  const myValue = myBalance * rate;
  const value0 = balance0 * rate0;
  const value1 = balance1 * rate1;
  const reserveValue0 = reserve0 * rate0;
  const reserveValue1 = reserve1 * rate1;
  let depositValue0 = 0;
  let depositValue1 = 0;
  let delta0 = 0;
  let delta1 = 0;
  let impermanentLoss = 0;
  let impermanentLossPercent = 0;
  if (deposits) {
    const [deposit0, deposit1] = deposits;
    depositValue0 = deposit0 * rate0;
    depositValue1 = deposit1 * rate1;
    const myDepositValue = depositValue0 + depositValue1;
    impermanentLoss = myDepositValue - myValue;
    impermanentLossPercent = impermanentLoss / myDepositValue;
    delta0 = balance0 - deposit0;
    delta1 = balance1 - deposit1;
  }

  return (
    myValue ?
      <Grid item key={id} css={styles.itemContainer}>
      <TableContainer css={styles.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pair</TableCell>
              <TableCell colSpan={2}>{id.replace(/_/g, " ")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{`Reserve ${token0}`}</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(reserve0, { pos: reserve0 > 100 ? 0 : 6 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(reserveValue0, { prefix: "$", pos: 0 })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{`Reserve ${token1}`}</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(reserve1, { pos: reserve1 > 100 ? 0 : 6 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(reserveValue1, { prefix: "$", pos: 0 })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Supply</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(totalSupply, { pos: totalSupply > 100 ? 0 : 6 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(totalValue, { prefix: "$", pos: 0 })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>My Balance</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(myBalance, { pos: myBalance > 100 ? 2 : 6 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(myValue, { prefix: "$" })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>My Share</TableCell>
              <TableCell css={styles.balancesCell}>
                {percentFormatter(myShare, { pos: myShare >= 0.01 ? undefined : 6 })}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{token0}</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(balance0, { coin: meta0.coin, pos: balance0 > 100 ? undefined : 6 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(value0, { prefix: "$" })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{token1}</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(balance1, { coin: meta1.coin, pos: balance1 > 100 ? undefined : 6 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(value1, { prefix: "$" })}
              </TableCell>
            </TableRow>
            {impermanentLoss ? <TableRow>
              <TableCell>{token0} Δ</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(delta0, { coin: meta0.coin })}
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow> : null}
            {impermanentLoss ? <TableRow>
              <TableCell>{token1} Δ</TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(delta1, { coin: meta1.coin })}
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow> : null}
            {impermanentLoss ? <TableRow>
              <TableCell>Imp. Loss</TableCell>
              <TableCell css={styles.balancesCell}>
                {percentFormatter(impermanentLossPercent, { pos: 3 })}
              </TableCell>
              <TableCell css={styles.balancesCell}>
                {balanceFormatter(impermanentLoss, { prefix: "$" })}
              </TableCell>
            </TableRow> : null}
          </TableBody>
        </Table>
      </TableContainer> 
      </Grid>
    : null
  );
}

function Pairs() {
  const styles = getAppStyles()
  const pairs = Assets.filter(e => e.type === AssetType.LP);
  
  return (
    <Grid css={styles.rightContainer} container spacing={2}>
      {pairs.map(pair => (
        <LiquidityPair key={pair.id} id={pair.id} />
      ))}
    </Grid>
  );
}

export default Pairs;

/*

function Fallback() {
  const styles = getStyles();
  return (
    <Paper>
      <Box css={styles.fallback} display="flex" justifyContent="center">
        <CircularProgress size={30} />
      </Box>
    </Paper>
  )
}

*/
