import { AssetId, Chain, Coin, Exchange, Fiat, IRatePair, RateType, Preset } from "../types"
import MinPairInfo from "../../config/abi/MinPairInfo.json";

const ratePairs: IRatePair[] = [
  /*
  {
    id: "SHARK:SX",
    type: RateType.INDIRECT,
    query: Coin.SHARK,
    base: Coin.SX,
    path: ["SHARK:USD", "SX:USD"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "ETH:SX",
    type: RateType.DERIVED_V2,
    query: Coin.ETH,
    base: Coin.SX,
    token1AsBase: true,
    chainId: Chain.SXN,
    platformId: Exchange.SHARKSWAP,
    address: "0x28d8f7E0F67c12cC49C128ec73C6265C7A36F84A",
    abi: MinPairInfo,
    token0: AssetId.WETH,
    token1: AssetId.SX,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  */
  {
    id: "SHARK:SX",
    type: RateType.DERIVED_V2,
    query: Coin.SHARK,
    base: Coin.SX,
    token1AsBase: true,
    chainId: Chain.SXN,
    platformId: Exchange.SHARKSWAP,
    address: "0x70bDD69Fe7E7090642f1539991E77aBA6cCF3212",
    abi: MinPairInfo,
    token0: AssetId.SX,
    token1: AssetId.SHARK,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },

  {
    id: "SHARK:USD",
    type: RateType.DERIVED_V2,
    query: Coin.SHARK,
    base: Fiat.USD,
    token1AsBase: true,
    chainId: Chain.SXN,
    platformId: Exchange.SHARKSWAP,
    address: "0x581E260c033e8CbdeAfAd0C243dE4c0a5c32341b",
    abi: MinPairInfo,
    token0: AssetId.SHARK,
    token1: AssetId.USDC,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.NEXT_GEN, Preset.SXN]
  },
  /*
  {
    id: "SHARK:USD",
    type: RateType.FIXED,
    query: Coin.SHARK,
    base: Fiat.USD,
    fixedRate: 0.05,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.NEXT_GEN, Preset.SXN, Preset.TORONTO,]
  },
  */
  {
    id: "SX:SHARK",
    type: RateType.INDIRECT,
    query: Coin.SX,
    base: Coin.SHARK,
    path: ["SHARK:SX"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "ETH:SHARK",
    type: RateType.INDIRECT,
    query: Coin.ETH,
    base: Coin.SHARK,
    path: ["ETH:SX", "SX:SHARK"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "BTC:SHARK",
    type: RateType.INDIRECT,
    query: Coin.BTC,
    base: Coin.SHARK,
    path: ["BTC:SX", "SHARK:SX"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "MATIC:SHARK",
    type: RateType.INDIRECT,
    query: Coin.MATIC,
    base: Coin.SHARK,
    path: ["MATIC:SX", "SHARK:SX"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "SX_USDC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SX_USDC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SX_USDC_KLP}:${Chain.SXR}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "SHARK_USDC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SHARK_USDC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SHARK_USDC_KLP}:${Chain.SXR}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },

  /*
  {
    id: "LFI:SX",
    type: RateType.DERIVED_V2,
    query: Coin.LFI,
    base: Coin.SX,
    token1AsBase: true,
    chainId: Chain.SXN,
    platformId: Exchange.SHARKSWAP,
    address: "0xb251C9B8712bd2253aEfc422fBD370b236f7AbDE",
    abi: MinPairInfo,
    token0: AssetId.LFI,
    token1: AssetId.SX,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "LFI:USD",
    type: RateType.INDIRECT,
    query: Coin.LFI,
    base: Fiat.USD,
    path: ["LFI:SX", "SX:USD"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  */

  /*
  {
    id: "SX_WETH_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SX_WETH_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SX_WETH_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "WETH_USDC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WETH_USDC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WETH_USDC_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "SX_USDC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SX_USDC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SX_USDC_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "SHARK_USDC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SHARK_USDC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SHARK_USDC_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "SX_SHARK_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SX_SHARK_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SX_SHARK_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "SX_WMATIC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.SX_WMATIC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.SX_WMATIC_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "USDC_WMATIC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.USDC_WMATIC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.USDC_WMATIC_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "WETH_WMATIC_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WETH_WMATIC_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WETH_WMATIC_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "LFI_SX_KLP:USD",
    type: RateType.COMPLEX,
    query: Coin.LFI_SX_KLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.LFI_SX_KLP}:${Chain.SXN}`,
    presets: [Preset.MCC, Preset.PERSONAL, Preset.NEXT_GEN, Preset.SXN]
  },
  */
]

export default ratePairs

