import { Pie } from 'react-chartjs-2';
import { ChartColors } from "./utils";
import { balanceFormatter, lv } from "../../utils";
import { useRecoilValueLoadable } from 'recoil';
import { summaryByGroupSelector } from '../../state/byGroup';

export function ByGroup() {

  // const summary: any = {};
  const summary = lv(useRecoilValueLoadable(summaryByGroupSelector));
  if (!summary) return null;

  const labels = Object.keys(summary);
  const values = Object.keys(summary).map(key => summary[key][1].toFixed());
  const total = Object.keys(summary).reduce((acc, key) => acc + summary[key][1], 0);

  const data = {
    labels,
    datasets: [
      {
        data: values, 
        backgroundColor: ChartColors,
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const { label: coin, formattedValue, parsed } = context;
            const amount = summary[coin][0];
            const pct = (100 * parsed / total).toFixed(1);
            const formattedAmount = balanceFormatter(amount, { coin });
            return [` ${coin}`, ` ${formattedAmount}`, ` $${formattedValue}`, ` ${pct}%`];
          }
        }
      },
      legend: {
        labels: {
          filter: (item: any, chart: any) => {
            return Number(chart.datasets[0].data[item.index]) > 0;
          }
        }
      }

    }
  };

  // console.log('------- by group data', data);

  return (
    <Pie data={data} options={options} />
  );

}
