import { useRecoilCallback } from "recoil";

export function DebugButton() {
  const onClick = useRecoilCallback(({snapshot}) => async () => {
    console.debug('Atom values:');
    const nodes = Array.from(snapshot.getNodes_UNSTABLE());
    nodes.sort((a, b) => a.key.localeCompare(b.key))
    for (let node of nodes) {
      const value = await snapshot.getPromise(node);
      console.debug(node.key, value);
    }
  }, []);

  return <button onClick={onClick}>Dump State</button>
}
