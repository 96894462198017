import { useLocation } from "react-router-dom"
import { Page } from "../config/types"
import { IScope } from "../state/app"
import { Assets } from "../config/assets"

export function usePage() {
  const { pathname } = useLocation()
  
  const paths = pathname.split("/")

  // console.log("------ pathname", pathname, paths)

  const paramPage = paths.length === 2 ? paths[1] : ""
  const validPages: string[] = Object.values(Page)
  const page = paramPage && validPages.includes(paramPage) ? paramPage as Page : Page.DASHBOARD
  return page
}

export function UseBalances(scope: IScope) {
  const { accounts, chains, coins, excludedCoins } = scope

  const assets = Assets.filter(a => (
    chains.includes(a.chainId) 
  ))

  


}