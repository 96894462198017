import { Stack } from "@mui/material";
import StakingRewards from "./StakingRewards";
import PendingSummary from "./PendingSummary";


function Staking() {

  return (
    <Stack direction="row" spacing={2} padding={2}>
      <StakingRewards />
      <PendingSummary />
    </Stack>
  )
}

export default Staking
