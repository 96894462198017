import _ from "lodash";
import { memo, useEffect } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { Assets } from "../../config/assets";
import { balanceState, IBalanceState } from "../../state/balances";
import { getAssetBalancesMulticall } from "../../services/balancesMulticall";
import { scopeSelector } from "../../state/app";
// import { useScope } from "../../hooks";

function BalanceFetcher() {
  const { accounts: scopeAccounts } = useRecoilValue(scopeSelector)
  
  const fetchBalances = useRecoilCallback(({ set, snapshot }) => async () => {
    // need to fetch balances in batches not to go over rpc provider rate limits
    // console.log("----------- fetchBalances", snapshot) ;

    let state: IBalanceState = {};
    for (let asset of Assets) {
      const balances = await getAssetBalancesMulticall(asset, asset.accounts || scopeAccounts);
      const update = _.fromPairs(balances);
      state = { ...state, [asset.id]: update };
    }
    set(balanceState, (value: IBalanceState) => {
      return state;
    });

  });

  useEffect(() => {
    // console.log("--------- BalanceFetcher before fetching", presets)
    if (scopeAccounts.length > 0) {
      fetchBalances();
    }
  }, [scopeAccounts, fetchBalances]);

  return null;
}

export default memo(BalanceFetcher);


/*

WETH:137
{
  <account>:100
}

      //set(balanceState, (value: IBalanceState) => {
      //  return { ...value, [asset.id]: update };
      //});


      const balances = await Promise.all((asset.accounts || allAccounts).map(async (account): Promise<[string, number | undefined]> => {
        const balance = await getAssetBalance(asset, account);
        return [account, balance];
      }));


      //for (let [account, balance] of balances || []) {
      //  set(balancesState({ id: asset.id, account }), balance);
      //}

*/
