import useSWR from "swr";
import { IScope } from "../../state/app";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "../subgraphs";
import farmingData from "../farming/farming.json"
import { fromWei } from "../../utils";

const FARMING_ACCOUNTS: any = [
  // "0x47378BFb91FcdD9a377D44C13f7Cff185129b6d5", // Bra 3
  "0xEd1a449E84E3DdEc2a31fE345f5Ee5A0f4020dc6", // Bra 4
  // "0x89181a0E0a9de0B5B21033e5b5A1dEBFcf097065", // Chr 2
  // "0xf0d6dE16e2c14e0598A18C2B43716d7D0672690c", // NextGen test
  // "0x093776982c0F6Ce635312EDa63048A4A9ED56A2D", // iPh 1
]

export interface ITokenMeta {
  address: string;
  symbol: string;
  decimals: number;
  amountIn: number;
  amountOut: number;
  label?: string;
  liquidityDelta?: number;
  harvested?: number;
}

export interface ITokenMap {
  [address: string]: ITokenMeta;
}

async function dataFetcher([accounts, startDate, endDate]: [
  accounts: string[],
  startDate: number,
  endDate: number
]) {

  const txs = (farmingData as any).items.filter((e: any) => (
    accounts.includes(e.account) &&
    e.ts >= startDate &&
    e.ts <= endDate
  ))

  const tokens = txs.reduce((acc: ITokenMap, tx: any) => {
    for (let transfer of (tx.token_transfers || [])) {
      const address = transfer.token.address
      if (!acc[address]) {
        acc[address] = {
          address,
          symbol: transfer.token.symbol,
          decimals: transfer.total.decimals,
          amountIn: 0,
          amountOut: 0,
        }
      }
      let item = acc[address]
      const amount = fromWei(transfer.total.value, transfer.total.decimals)
      if (transfer.from.hash === tx.account) {
        item.amountOut += amount
      } else if (transfer.to.hash === tx.account) {
        item.amountIn += amount
      }
      if (item.symbol !== "SLP") {
        if (tx.method.startsWith("addLiquidity")) {
          item.liquidityDelta = (item.liquidityDelta || 0) - amount
        } else if (tx.method.startsWith("removeLiquidity")) {
          item.liquidityDelta = (item.liquidityDelta || 0) + amount
        } else if (tx.method.toLowerCase().includes("harvest") || tx.method === "deposit") {
          item.harvested = (item.harvested || 0) + amount
        } 
      }
    }
    return acc
  }, {})

  return { txs, tokens }
}

export function useFarmingTxs(shouldFetch: boolean, scope: IScope) {
  const swrOptions = {
    revalidateOnFocus: false,
  };

  // const pools = scope.pools
  const accounts = scope.accounts || FARMING_ACCOUNTS
  const startDate = scope.startDate || DEFAULT_START_DATE
  const endDate = scope.endDate || DEFAULT_END_DATE  
  const fetchData = shouldFetch

  const { data, error } = useSWR(
    fetchData ? [accounts, startDate, endDate] : null, 
    dataFetcher,
    swrOptions
  );

  if (error) {
    console.log("-------- fetching farming data error", error);
  }
  // console.log("--------- staking query", endpoint, query, variables)
  // console.log("--------- staking data", data);


  return { data, error }
}

/*

// import { DynamoDB } from "aws-sdk";
import * as AWS from 'aws-sdk';

const AWS_REGION = "us-east-1"
const TABLE_NAME = "coins-farming"
const ACCESS_KEY_ID = "AKIAYYJMLBFPQSH6VNFP"
const SECRET_ACCESS_KEY = "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"


AWS.config.update({
  region: AWS_REGION,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const docClient = new AWS.DynamoDB.DocumentClient();

  const params: AWS.DynamoDB.DocumentClient.QueryInput = {
    TableName: TABLE_NAME,
    FilterExpression: '#account IN (:accounts) AND #timestamp BETWEEN :start_date AND :end_date',
    ExpressionAttributeNames: {
      "#timestamp": "ts",
      "#account": "account",
    },
    ExpressionAttributeValues: {
      ":start_date": startDate,
      ":end_date": endDate,
       ":accounts": accounts,
    },

    / *
    FilterExpression: '#id <> :empty',
    ExpressionAttributeNames: {
      "#id": "id"
    },
    ExpressionAttributeValues: {
      ":empty": ""
    }
    * /
    // KeyConditionExpression: "#timestamp BETWEEN :start_date AND :end_date",
    // FilterExpression: '#account IN (:accounts)',
    // ExpressionAttributeNames: {
    //  "#timestamp": "ts",
      // "#account": "account",
    //},
    //ExpressionAttributeValues: {
    //  ":start_date": startDate,
    //  ":end_date": endDate,
      // ":accounts": accounts,
    //},
  };

  let data: any
  try {
    data = await docClient.scan(params).promise();
    console.log('Scan succeeded:', JSON.stringify(data, null, 2));
  } catch (error) {
    console.error('Unable to fetch items. Error JSON:', JSON.stringify(error, null, 2));
  }

  return { data }

  / *
  const client = new DynamoDB({ region: AWS_REGION })
  const ddbDocClient = DynamoDBDocument.from(client)
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: '#timestamp BETWEEN :start_date AND :end_date AND #account IN (:accounts)',
    ExpressionAttributeNames: {
      '#timestamp': 'ts',
      '#account': 'account'
    },
    ExpressionAttributeValues: {
      ':start_date': startDate,
      ':end_date': endDate,
      ':accounts': accounts
    },
  }
  
  // Create a QueryCommand
  const queryCommand = new QueryCommand(params)
  const result = await ddbDocClient.send(queryCommand)
  * /

  // console.log("-------- result", result)

*/
