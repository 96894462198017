/** @jsxImportSource @emotion/react */
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material"
import { balanceFormatter } from "../../utils"
import { getTableStyles } from "../shared/styles"
import { ITokenMeta } from "../../services/farming"


function FarmingSummary(params: { tokens: ITokenMeta[] }) {

  const { tokens: allTokens } = params
  const tokens = allTokens.filter(e => e.symbol !== "SLP")
  const styles = getTableStyles()

  return (tokens ?
    <Box>
      <TableContainer css={styles.container} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Farming
              </TableCell>
              {tokens.map(token => (
                <TableCell align="right" key={`col-${token.address}`}>
                  {token.symbol}
                </TableCell>
              ))}
              <TableCell align="right">USD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Liquidity</TableCell>
              {tokens.map(token => (
                <TableCell align="right" key={`liq-${token.address}`}>
                  {balanceFormatter(token.liquidityDelta || 0)}
                </TableCell>
              ))}
              <TableCell align="right">
                {balanceFormatter(0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Harvested</TableCell>
              {tokens.map(token => (
                <TableCell align="right" key={`liq-${token.address}`}>
                  {balanceFormatter(token.harvested || 0)}
                </TableCell>
              ))}
              <TableCell align="right">
                {balanceFormatter(0)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>Total</TableCell>
              {tokens.map(token => (
                <TableCell align="right" key={`liq-${token.address}`}>
                  {balanceFormatter((token.harvested || 0) + (token.liquidityDelta || 0))}
                </TableCell>
              ))}
              <TableCell />    
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box> : null
  )
}

export default FarmingSummary
