/** @jsxImportSource @emotion/react */
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Box, TextField } from '@mui/material'
import { useRecoilState } from 'recoil'
import { selectedStartDateState, selectedEndDateState } from '../../state/app'
import moment, { Moment } from 'moment'
import { getStyles } from './styles'

function DateRangeSelector() {
  const styles = getStyles()
  const [startDate, setStartDate] = useRecoilState(selectedStartDateState)
  const [endDate, setEndDate] = useRecoilState(selectedEndDateState)

  const handleStartDateChange = (value: Moment | null) => {
    if ((value && value.isValid()) || value === null) {
      setStartDate(value ? value.unix() : null)
    }
  }

  const handleEndDateChange = (value: Moment | null) => {
    if ((value && value.isValid()) || value === null) {
      setEndDate(value ? value.unix() : null)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box css={styles.dateRange}>
        <DesktopDatePicker
          css={styles.datePicker}
          label=""
          inputFormat="YYYY-MM-DD"
          value={startDate ? moment.unix(startDate) : null}
          onChange={handleStartDateChange}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              {...params} 
            />
          )}
        />
        <DesktopDatePicker
          css={styles.datePicker}
          label=""
          inputFormat="YYYY-MM-DD"
          value={endDate ? moment.unix(endDate) : null}
          onChange={handleEndDateChange}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              {...params} 
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default DateRangeSelector
