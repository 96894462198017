import { BigNumberish, utils } from "ethers";
import moment from "moment";
import numeral from "numeral";
import { toChecksumAddress } from "ethereum-checksum-address"
import { Accounts } from "../config/accounts";
import { FormatterConfigs } from "../config/formatters";
import { Address } from "../config/types";

export function fromWei(value: BigNumberish, decimals: number = 18) {
  // return Number(utils.formatUnits(value, decimals));
  return Number(Number(utils.formatUnits(value, decimals)).toFixed(8))
}

export function toWei(value: number, decimals: number = 18) {
  return utils.parseUnits(value.toFixed(), decimals).toString();
}

export function isEthereumAddress(address: string) {
  return address.startsWith("0x");
}

export function isBitcoinAddress(address: string) {
  return address.startsWith("bc");
}

export interface IFormatterProps {
  coin?: string;
  prefix?: string;
  usePrefix?: boolean;
  showZero?: boolean;
  pos?: number;
}

export function balanceFormatter(balance: number, props?: IFormatterProps) {
  const { coin, usePrefix, prefix: passedPrefix, pos: passedPos, showZero } = props || {};
  const config = coin ? FormatterConfigs[coin] || {} : {};
  const { prefix: configPrefix, pos: configPos } = config;

  const prefix = (passedPrefix || (usePrefix && (configPrefix || coin + " ")) || "");
  const pos = passedPos === undefined ? (configPos || (balance > 0 && balance < 0.1 ? 4 : 2)) : passedPos;

  const isBlank = !showZero && (balance === 0 || balance === null || isNaN(balance));
  const formatStr = "0,0" + (pos ? "." + "0".repeat(pos) : "");

  let formatted: string;

  if (balance > 0 && balance < 1e-6) { // numeral incorrectly formats values < 1e-6 as NaN
    formatted = `${prefix || ""}${numeral(0).format(formatStr)}`;
  } else {
    formatted = isBlank ? "" : `${prefix || ""}${numeral(balance).format(formatStr)}`;
  }
  return formatted;
}

export function percentFormatter(value: number, props?: IFormatterProps) {
  const { pos: passedPos } = props || {};
  const pos = passedPos || 3;
  const formatStr = "0,0." + "0".repeat(pos);
  return `${numeral(value * 100).format(formatStr)}%`;
}

export function lv (loadable: any) {
  return (
    loadable.state === "hasValue" ? loadable.contents : undefined
  );
}

export function getAccountLabel(accountAddress: Address) {
  const account = Accounts.find(e => e.address === toChecksumAddress(accountAddress))
  return (
    account ? `${account.walletId} ${account.label}` : ""
  )
}

export const timestampFormatter = (timestampSeconds: number, format = "YYYY-MM-DD HH:mm:ss") => {
  return moment(new Date(timestampSeconds * 1000)).format(format);
};

