import { providers, utils } from "ethers";
import { RPC_URLS, STATIC_RPC_URLS, WS_URLS } from "../config/providers";
import { Chain } from "../config/types";

let cachedWeb3Provider: providers.Web3Provider | undefined;

let rpcProviders: {
  [chainId: number]: providers.FallbackProvider;
} = {};

let wsProviders: {
  [chainId: number]: providers.WebSocketProvider;
} = {};

let staticProviders: {
  [chainId: number]: providers.StaticJsonRpcProvider;
} = {};

export function getStaticProvider(chainId: number) {
  if (!staticProviders[chainId]) {
    if ([Chain.POLYGON, Chain.MAINNET, Chain.ARBITRUM, Chain.SXN, Chain.SXR, Chain.TORONTO].includes(chainId)) {
      const url = STATIC_RPC_URLS[chainId];
      staticProviders[chainId] = new providers.StaticJsonRpcProvider(url, chainId);
    }
  }
  return staticProviders[chainId]!;
}

export function getWsProvider(chainId: number) {
  if (!wsProviders[chainId]) {
    if ([Chain.POLYGON, Chain.MAINNET, Chain.ARBITRUM, Chain.SXN, Chain.SXR, Chain.TORONTO].includes(chainId)) {
      const url = WS_URLS[chainId];
      wsProviders[chainId] = new providers.WebSocketProvider(url, chainId);
    }
  }
  return wsProviders[chainId]!;
}

export function getRpcProvider(chainId: number) {
  if (!rpcProviders[chainId]) {
    if ([Chain.POLYGON, Chain.MAINNET, Chain.ARBITRUM, Chain.SXN, Chain.SXR, Chain.TORONTO].includes(chainId)) {
      const urls = RPC_URLS[chainId];
      let chainProviders: providers.StaticJsonRpcProvider[] = [];
      for (let url of urls) {
        if (url) {
          chainProviders.push(new providers.StaticJsonRpcProvider(url, chainId));
        }
      }
      const chainProvidersWithPriority = chainProviders.map((provider, i) => ({
        provider,
        priority: i + 1
      })); 
      rpcProviders[chainId] = new providers.FallbackProvider(
        chainProvidersWithPriority, 1
      );
    }
  }
  return rpcProviders[chainId]!;
}

export function getCachedProvider() {
  try {
    if (!cachedWeb3Provider) {
      throw new Error("No cached web3 found");
    }
    return cachedWeb3Provider;
  } catch (err) {
    throw err;
  }
}

export async function getInjectedWeb3() {
  try {
    if (window.ethereum) {
      const { ethereum } = window;
      await ethereum.request({ method: "eth_requestAccounts" });
      return new providers.Web3Provider(ethereum, "any");
    } else {
      throw new Error(`Cannot get injected Web3`);
    }
  } catch (e) {
    console.error("-------- getInjectedWeb3 error", e);
    throw e;
  }
}

export async function connectWallet() {
  try {
    cachedWeb3Provider = await getInjectedWeb3();
  } catch (e) {
    console.error("--------- connectWallet", e);
    throw e;
  }
}

export async function disconnectWallet() {
  try {
    cachedWeb3Provider = undefined;
  } catch (e) {
    throw e;
  }
}

enum WalletTypes {
  METAMASK = "metamask",
  UNKNOWN = "unknown"
}

export interface IWalletDetails {
  type: WalletTypes;
  accountAddress: string;
  chainId: number;
  balance: string;
}

export async function getWalletDetails(
): Promise<IWalletDetails> {
  try {
    if (!cachedWeb3Provider) {
      throw new Error("No web3 found");
    }
    // debugger
    const walletType = cachedWeb3Provider.provider.isMetaMask ? WalletTypes.METAMASK : WalletTypes.UNKNOWN;
    const accounts = await cachedWeb3Provider.listAccounts();
    if (accounts.length === 0) throw new Error("No account");
    const accountAddress = accounts[0];
    const network = await cachedWeb3Provider.getNetwork();
    const balance = await cachedWeb3Provider.getBalance(accountAddress);

    const details: IWalletDetails = {
      type: walletType,
      accountAddress: utils.getAddress(accountAddress),
      chainId: network.chainId,
      balance: balance.toString(),
    };

    return details;
  } catch (err) {
    console.error("-------- getWalletDetails error", err);
    throw err;
  }
}
