import { toChecksumAddress } from "ethereum-checksum-address"
import request from "graphql-request";
import useSWR from "swr";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from ".";
import { subgraphEndpoints } from "../../config";
import { Tokens } from "../../config/assets";
import { Chain, Exchange, SubgraphType, TxType } from "../../config/types";
import { IScope } from "../../state/app";
import { isEthereumAddress } from "../../utils";
import { IExchangeSwapTxRaw, mapTxRaw } from "./shared";
import { ISwapTxState } from "./types";

const fetcher = ({ endpoint, query, ...variables }: any) => (
  request(endpoint, query, variables)
)

const query = /* GraphQL */ `
query ($accounts: [String!]!, $startDate: Int, $endDate: Int) {
  swaps(
    first: 1000
    orderBy: timestamp 
    orderDirection: desc
    where: {
      to_in: $accounts
      timestamp_gte: $startDate
      timestamp_lte: $endDate 
  }) {
    id
    timestamp
    transaction {
      id
      blockNumber
      swaps {
        id
        amount0In
        amount1In
        amount0Out
        amount1Out
        sender
        to
        logIndex
        amountUSD
        pair {
          id
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
        }
      }
    }
    pair {
      id
      token0 {
        id
        symbol
      }
      token1 {
        id
        symbol
      }
    }
    amount0In
    amount1In
    amount0Out
    amount1Out
    sender
    to
    logIndex
    amountUSD
  }
}
`

export function useExchangeSwapTxs(
  chainId: Chain,
  exchange: Exchange,
  scope: IScope,
) {
  const toFetch = scope.chains.includes(chainId) && scope.exchanges.includes(exchange)
  const endpoint = subgraphEndpoints[chainId][exchange][SubgraphType.EXCHANGE]
  const variables = {
    accounts: scope.accounts.filter(e => isEthereumAddress(e)),
    startDate: scope.startDate || DEFAULT_START_DATE,
    endDate: scope.endDate || DEFAULT_END_DATE
  }

  // const { data, error, isValidating } = useSWR(toFetch ? [endpoint, swapsQuery, variables] : null, fetcher, config)
  const { data, error } = useSWR(toFetch ? { ...variables, endpoint, query } : null, fetcher)

  // console.log("-------- exchange params", toFetch, endpoint, variables)
  // console.log("-------- exchange results", data, error, isValidating)

  if (!endpoint) return { data: [] }
  if (error) return { error }
  if (!data && !error) return { loading: true }

  let swapsRaw: IExchangeSwapTxRaw[] = []

  for (let swap of data.swaps) {
    const mapped = mapTxRaw(swap, TxType.SWAP)
    if (mapped) {
      swapsRaw.push(mapped as IExchangeSwapTxRaw)      
    }
  } 

  let swaps: ISwapTxState[] = []

  for (let swapRaw of swapsRaw) {
    let txSwaps = [...swapRaw.swaps]
    txSwaps.sort((a: any, b: any) => a.logIndex - b.logIndex)
    const swapIn = txSwaps[0]
    const swapOut = txSwaps[txSwaps.length - 1]      
    const indexIn = Number(swapIn.amount0In) > 0 ? 0 : 1
    const indexOut = Number(swapOut.amount0Out) > 0 ? 0 : 1
    const addressIn = toChecksumAddress(swapIn[`token${indexIn}Address`])
    const addressOut = toChecksumAddress(swapOut[`token${indexOut}Address`])
    const tokenIn = Tokens.find(e => e.address === addressIn && e.chainId === chainId)!
    const tokenOut = Tokens.find(e => e.address === addressOut && e.chainId === chainId)!
    if (!tokenIn) {
      console.log("-------- missing token in", addressIn)
      break
    }
    if (!tokenOut) {
      console.log("-------- missing token out", addressOut)
      break
    }
    const coinIn = tokenIn.coin
    const coinOut = tokenOut.coin

    swaps.push({
      chainId,
      exchange,
      txHash: swapRaw.transactionHash,
      timestamp: swapRaw.timestamp,
      block: swapRaw.block,
      account: toChecksumAddress(swapRaw.to),
      symbolIn: swapIn[`token${indexIn}Symbol`],
      symbolOut: swapOut[`token${indexOut}Symbol`],
      coinIn,
      coinOut,
      addressIn,
      addressOut,
      amountIn: Number(swapIn[`amount${indexIn}In`]),
      amountOut: Number(swapOut[`amount${indexOut}Out`]),
      amountUSD: Number(swapRaw.amountUSD),
    })
  }

  return { data: swaps }
}

/*
  const config = {

    // loadingTimeout: 1000,
    // shouldRetryOnError: false,
    // revalidateIfStale: false,
    // revalidateOnMount: false,
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
    // dedupingInterval: 10_000,
    // refreshInterval: 10_000
    // onLoadingSlow: (key: any, config: any) => {
    //   console.log("------ onLoadingSlow", key, config)
    // }
  }

*/

//const fetcher = (endpoint: string, query: string, variables: any) => (
//  request(endpoint, query, variables)
//)

/*
const fetcher = async (endpoint: string, query: string, variables: any) => {
  console.log("--------- before request")
  const response = await request(endpoint, query, variables)

  console.log("--------- response", response)
  return response
}
*/

