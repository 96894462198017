import { useRecoilValue } from "recoil";
// import { Tokens } from "../../config/assets";
import { Chain, Exchange } from "../../config/types";
import { IScope, scopeSelector } from "../../state/app";
import { ISwapTxState } from "./types";
import { useExchangeSwapTxs } from "./exchangeSwapTxs";
import { useParaswapSwapTxs } from "./paraswapTxs";
// import { useExchangeLpTxs } from "./exchangeLpTxs";
import { useExchangeSnapshots } from "./exchangeSnapshots";

export const DEFAULT_START_DATE = 1609459200 // 2021-01-01
export const DEFAULT_END_DATE = 1893456000 // 2030-01-01


function isSwapIncluded(swap: ISwapTxState, scope: IScope) {
  // const tokenIn = Tokens.find(e => e.address === swap.addressIn)!
  // const tokenOut = Tokens.find(e => e.address === swap.addressOut)!

  // filter out wraps/unwraps, stable to stable swaps, and those out of current scope
  return (
    swap.coinIn !== swap.coinOut &&
    // !(tokenIn.category === AssetCategory.STABLE_COIN && tokenOut.category === AssetCategory.STABLE_COIN) &&
    (scope.coins.includes(swap.coinIn) || scope.coins.includes(swap.coinOut)) &&
    !(scope.excludedCoins.includes(swap.coinIn) || scope.excludedCoins.includes(swap.coinOut)) &&
    scope.exchanges.includes(swap.exchange)
  )
}

export function useSwapTransactions() {
  const scope = useRecoilValue(scopeSelector)

  const { data: data1 } = useParaswapSwapTxs(Chain.POLYGON, Exchange.PARASWAP, scope) // , error: error1, loading: loading1
  const { data: data2 } = useExchangeSwapTxs(Chain.SXN, Exchange.SHARKSWAP, scope) // , error: error2, loading: loading2
  const { data: data3 } = useExchangeSwapTxs(Chain.POLYGON, Exchange.SUSHISWAP, scope) //, error: error3, loading: loading3
  // const { data: data4 } = useExchangeSwapTxs(Chain.POLYGON, Exchange.QUICKSWAP, scope) //, error: error3, loading: loading3

  console.log("--------- paraswap swaps", data1)

  let data = [
    ...(data1 || []), 
    ...(data2 || []), 
    ...(data3 || []),
    // ...(data4 || []),
  ]
    .filter(swap => isSwapIncluded(swap, scope)) 
    .sort((a, b) => b.timestamp - a.timestamp)

  return data
}

export function useLpTransactions() {
  const scope = useRecoilValue(scopeSelector)

  // const { data: sharkswapData } = useExchangeLpTxs(Chain.SXN, Exchange.SHARKSWAP, scope)

  const { data: sharkswapData } = useExchangeSnapshots(Chain.SXN, Exchange.SHARKSWAP, scope)

  return [...(sharkswapData || [])]
}
