/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { Fragment, memo } from "react";
import { AssetType, Chain, IAsset } from "../../config/types";
import { balanceFormatter } from "../../utils";

function getStyles() {
  return {
    prefix: css`
      font-size: 9px;
      color: #535353;
      margin-right: 5px;
    `,
    mainnet_native: css`
      color: #646464;
      font-weight: 500;
    `,
    mainnet_deprecated: css`
      color: #a2a2a2;
      font-weight: 400;
    `,
    polygon: css`
      color: #5f008b;
      font-weight: 400;
    `,
    polygon_native: css`
      color: #8701c5;
      font-weight: 500;
    `,
    polygon_deposit: css`
      color: #b334ee;      
    `,
    polygon_borrow: css`
      color: #b334ee;      
    `,
    sxn: css`
      color: #047c0a;
      font-weight: 400;      
    `,
    sxn_native: css`
      color: #00af09;
      font-weight: 500;      
    `,
    sxn_deprecated: css`
      color: #9ba69c;
      font-weight: 400;      
    `,
    sxr: css`
      color: tomato;
      font-weight: 400;      
    `,
    sxr_native: css`
      color: coral;
      font-weight: 500;      
    `,
    sxr_deprecated: css`
      color: peachpuff;
      font-weight: 400;      
    `,
    arbitrum: css`
      color: #023691;
      font-weight: 400;      
    `,
    arbitrum_native: css`
      color: #3c5e94;
      font-weight: 500;      
    `,
    toronto: css`
      color: yellow;
      font-weight: 400;      
    `,
    toronto_native: css`
      color: yellow;
      font-weight: 500;      
    `,

  }
}
/*

#e52b01
#e75636

*/

function Balance(params: { asset: IAsset, balance: number | undefined }) {
  const { asset, balance } = params;
  const { id, coin, type } = asset;
  const styles: any = getStyles();

  const getNetworkClass = (id: string) => {
    const [, chainId] = id.split(":");
    // return (Number(chainId) as Chain) === Chain.POLYGON ? "polygon" : null;
    return (
      Chain[Number(chainId)].toLowerCase() + 
      ([AssetType.NATIVE, AssetType.DEPOSIT, AssetType.BORROW, AssetType.DEPRECATED].includes(type) ? `_${type.toLowerCase()}` : "")
    )
  }
  
  const formatted = balance ? balanceFormatter(balance, { coin }) : "";
  const networkClass = getNetworkClass(id);
  return (
    formatted ? <Fragment key={id}>
      <Typography variant="body2" css={networkClass ? styles[networkClass] : null}>
        {[AssetType.LP, AssetType.LP_STAKED].includes(asset.type) ? <small css={styles.prefix}>{asset.assetId}</small> : null}
        {formatted}
      </Typography>
    </Fragment> : null
  )
}
  
export default memo(Balance);
