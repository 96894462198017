import axios from "axios";
import { toChecksumAddress } from "ethereum-checksum-address"
import { subgraphEndpoints } from "../../config";
import { Address, Chain, Exchange, SubgraphType } from "../../config/types";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from ".";
import { ITokenRatesHistorical } from "./tokenRates";

const query = /* GraphQL */ `
  query($tokens: [String!]!, $startDate: Int, $endDate: Int) {
    tokenDayDatas (
      first: 1000
      skip: 0
      orderBy: date
      orderDirection: desc
      where: {
        token_in: $tokens
        date_gte: $startDate
        date_lte: $endDate
     }
    ) {
      id
      priceUSD
      date
    }
  }
`

export async function getTokenRatesPolygon(params: {
  tokens: Address[];
  startDate: number;
  endDate: number;
}) {
  const { tokens, startDate, endDate } = params

  const endpoint = subgraphEndpoints[Chain.POLYGON][Exchange.SUSHISWAP][SubgraphType.EXCHANGE]
  const variables = {
    tokens: tokens.map(e => e.toLowerCase()),
    startDate: startDate || DEFAULT_START_DATE,
    endDate: endDate || DEFAULT_END_DATE,
  }

  console.log("------- getTokenRatesPolygon params", endpoint, query, variables)

  const response = await axios.post(
    endpoint,
    JSON.stringify({
      query,
      variables,
    }),
    { headers: { "Content-Type": "application/json" }}
  );

  let rates: ITokenRatesHistorical = {}
  const { data } = response

  if (data) {
    for (let e of data.data.tokenDayDatas) {
      const tokenAddress = toChecksumAddress(e.id.split("-")[0])
      const rate = Number(e.priceUSD)
      if (rate) {
        if (!rates[tokenAddress]) {
          rates[tokenAddress] = {}
        }
        rates[tokenAddress][e.date] = rate
      }
    }
  }

  console.log("------- getTokenRatesPolygon data", data)
  console.log("------- getTokenRatesPolygon rates", rates)

  return rates
}
