import { AssetId, Chain, Coin, Exchange, Fiat, IRatePair, RateType, Preset } from "../types"
import MinPairInfo from "../../config/abi/MinPairInfo.json";

const ratePairs: IRatePair[] = [
  {
    id: "ETH:USD",
    type: RateType.DERIVED_V2,
    query: Coin.ETH,
    base: Fiat.USD,
    chainId: Chain.POLYGON,
    platformId: Exchange.SUSHISWAP,
    address: "0x34965ba0ac2451A34a0471F04CCa3F990b8dea27",
    abi: MinPairInfo,
    token0: AssetId.USDC,
    token1: AssetId.WETH,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN, Preset.TORONTO]
  },
  {
    id: "BTC:ETH",
    type: RateType.DERIVED_V2,
    query: Coin.BTC,
    base: Coin.ETH,
    token1AsBase: true,
    chainId: Chain.POLYGON,
    platformId: Exchange.SUSHISWAP,
    address: "0xE62Ec2e799305E0D367b0Cc3ee2CdA135bF89816",
    abi: MinPairInfo,
    token0: AssetId.WBTC,
    token1: AssetId.WETH,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE]
  },
  {
    id: "MATIC:USD",
    type: RateType.DERIVED_V2,
    query: Coin.MATIC,
    base: Fiat.USD,
    token1AsBase: true,
    chainId: Chain.POLYGON,
    platformId: Exchange.SUSHISWAP,
    address: "0xcd353f79d9fade311fc3119b841e1f456b54e858",
    abi: MinPairInfo,
    token0: AssetId.WMATIC,
    token1: AssetId.USDC,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN, Preset.TORONTO]
  },
  {
    id: "MATIC:SX",
    type: RateType.DERIVED_V2,
    query: Coin.MATIC,
    base: Coin.SX,
    token1AsBase: true,
    chainId: Chain.POLYGON,
    platformId: Exchange.APESWAP,
    address: "0xf90331cb67f9ae85739f5aedfa93dec68b7ce32e",
    abi: MinPairInfo,
    token0: AssetId.WMATIC,
    token1: AssetId.SX,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },
  /*
  {
    id: "LFI:ETH",
    type: RateType.DERIVED_V2,
    query: Coin.LFI,
    base: Coin.ETH,
    token1AsBase: false,
    chainId: Chain.POLYGON,
    platformId: Exchange.QUICKSWAP,
    address: "0x72CF5ee9ee918a529b25BBcB0372594008178535",
    abi: MinPairInfo,
    token0: AssetId.WETH,
    token1: AssetId.LFI,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.NEXT_GEN, Preset.SXN]
  },
  {
    id: "LFI:USD",
    type: RateType.INDIRECT,
    query: Coin.LFI,
    base: Fiat.USD,
    path: ["LFI:ETH", "ETH:USD"],
    presets: [Preset.PERSONAL, Preset.MCC, Preset.NEXT_GEN, Preset.SXN]
  },
  */
]

export default ratePairs

/*
  {
    id: "ETH:SX",
    type: RateType.DERIVED,
    query: Coin.ETH,
    base: Coin.SX,
    token1AsBase: true,
    chainId: Chain.POLYGON,
    platformId: Exchange.SUSHISWAP,
    address: "0x1bF9805B40a5f69c7d0f9E5d1Ab718642203c652",
    abi: MinPairInfo,
    token0: AssetId.WETH,
    token1: AssetId.SX,
    presets: [Preset.PERSONAL, Preset.MCC, Preset.STORAGE, Preset.NEXT_GEN, Preset.SXN]
  },

  {
    id: "WETH_SX_SLP:USD",
    type: RateType.COMPLEX,
    query: Coin.WETH_SX_SLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.WETH_SX_SLP}:${Chain.POLYGON}`,
    presets: [Preset.MCC, Preset.PERSONAL]
  },
*/

