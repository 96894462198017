import { Fiat, IRatePair, RateType } from "../types"
import { AssetId, Chain, Coin, Exchange, Preset } from "../types"
import MinPairInfo from "../../config/abi/MinPairInfo.json";

const ratePairs: IRatePair[] = [
  {
    id: "SUSHI:ETH",
    type: RateType.DERIVED_V2,
    query: Coin.SUSHI,
    base: Coin.ETH,
    token1AsBase: true,
    chainId: Chain.MAINNET,
    platformId: Exchange.SUSHISWAP,
    address: "0x795065dcc9f64b5614c407a6efdc400da6221fb0",
    abi: MinPairInfo,
    token0: AssetId.SUSHI,
    token1: AssetId.WETH,
    presets: [Preset.NEXT_GEN]
  },
  {
    id: "XSUSHI:ETH",
    type: RateType.DERIVED_V2,
    query: Coin.XSUSHI,
    base: Coin.ETH,
    token1AsBase: true,
    chainId: Chain.MAINNET,
    platformId: Exchange.SUSHISWAP,
    address: "0x36e2fcccc59e5747ff63a03ea2e5c0c2c14911e7",
    abi: MinPairInfo,
    token0: AssetId.XSUSHI,
    token1: AssetId.WETH,
    presets: [Preset.NEXT_GEN]
  },
  {
    id: "OHM:USD",
    type: RateType.DERIVED_V2,
    query: Coin.OHM,
    base: Fiat.USD,
    token1AsBase: true,
    chainId: Chain.MAINNET,
    platformId: Exchange.SUSHISWAP,
    address: "0x34d7d7aaf50ad4944b70b320acb24c95fa2def7c",
    abi: MinPairInfo,
    token0: AssetId.OHM,
    token1: AssetId.DAI,
    presets: [Preset.NEXT_GEN]
  },
  {
    id: "DYDX:ETH",
    type: RateType.DERIVED_V2,
    query: Coin.DYDX,
    base: Coin.ETH,
    token1AsBase: true,
    chainId: Chain.MAINNET,
    platformId: Exchange.SUSHISWAP,
    address: "0xe4ebd836832f1a8a81641111a5b081a2f90b9430",
    abi: MinPairInfo,
    token0: AssetId.DYDX,
    token1: AssetId.ETH,
    presets: [Preset.NEXT_GEN]
  },
  {
    id: "DYDX:USD",
    type: RateType.INDIRECT,
    query: Coin.DYDX,
    base: Fiat.USD,
    path: ["DYDX:ETH", "ETH:USD"],
    presets: [Preset.NEXT_GEN]
  },
  {
    id: "DYDX_WETH_SLP:USD",
    type: RateType.COMPLEX,
    query: Coin.DYDX_WETH_SLP,
    base: Fiat.USD,
    liquidityPair: `${AssetId.DYDX_WETH_SLP}:${Chain.MAINNET}`,
    presets: [Preset.NEXT_GEN]
  },


]

export default ratePairs
