/** @jsxImportSource @emotion/react */
import { Box, Checkbox, Chip, Input, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { Coin } from "../../config/types";
import { coinOptions, excludedCoinsState } from "../../state/app";
import { getStyles } from "./styles";

function ExcludedCoinSelector() {
  const styles = getStyles()
  const [coins, setCoins] = useRecoilState(excludedCoinsState)

  const handleChange = (event: SelectChangeEvent<Coin[]>) => {
    const { target: { value }} = event;
    setCoins(value as Coin[]);
  };

  return (
    <Select
      multiple
      displayEmpty
      value={coins}
      onChange={handleChange}
      input={<Input disableUnderline />}
      renderValue={(selected) => (
        <Box css={styles.selector} display="flex">
          <Chip label={`-${selected.length} coin`} size="small" />
        </Box>
      )}
    >
      {coinOptions.map((coin) => (
        <MenuItem key={coin} value={coin}>
          <Checkbox checked={coins.includes(coin)} />
          <Box>
            <Typography variant="body2">{coin}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}

export default ExcludedCoinSelector
