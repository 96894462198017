/** @jsxImportSource @emotion/react */
import { useRecoilValue } from "recoil";
import { usePage } from "../../hooks";
import { scopeSelector } from "../../state/app";
import { Chain, Page } from "../../config/types";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { getTableStyles } from "../shared/styles";
import { balanceFormatter, getAccountLabel, timestampFormatter } from "../../utils";
import { useStakingData } from "../../services/staking";
import { Fragment } from "react";

function StakingRewards() {
  const styles = getTableStyles()
  const page = usePage();
  // console.log("-------- staking page", page);
  const scope = useRecoilValue(scopeSelector);
  // console.log("-------- staking scope", scope);

  const shouldFetch = page === Page.STAKING;
  const { data } = useStakingData(shouldFetch, scope);
  const txs = data?.txs
  const summary = data?.summary

  console.log("-------- staking events summary", txs, summary);

  const totalAmountUSD = Object.values(summary || {}).reduce((acc, e) => acc + e.amountUSD, 0)
  const totalCurrentValueUSD = Object.values(summary || {}).reduce((acc, e) => acc + e.currentValueUSD, 0)


  return (txs && summary ?
    <TableContainer css={styles.container} component={Paper}>
      <Typography css={styles.counter} variant="body2">{txs.length}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">Account</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">Chain</Typography>
            </TableCell>
            {
              Object.keys(summary).map(coin => (
                <Fragment key={coin}>
                  <TableCell align="right">
                    <Typography variant="subtitle2">{coin}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography css={styles.extraPrefix} variant="subtitle2">{`${coin} (USD)`}</Typography>
                  </TableCell>
                </Fragment>
              ))
            }
            <TableCell>
              <Typography variant="subtitle2" align="right">SX Price</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" align="right">USD Value</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">Tx hash</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            txs.map((tx, i) => {
              const amountUSD = Object.values(tx.amountsUSD).reduce((acc, v) => acc + v, 0)
              const sxPrice = tx.amountsUSD.SX / tx.amounts.SX
              return (
                <TableRow key={tx.txHash}>
                  <TableCell>
                    {timestampFormatter(tx.timestamp)}
                  </TableCell>
                  <TableCell>
                    {getAccountLabel(tx.accountAddress)}
                  </TableCell>
                  <TableCell>
                    {Chain[tx.chainId].toLowerCase()}
                  </TableCell>
                  {
                    Object.keys(summary).map(coin => {
                      return (
                        <Fragment key={`${i}:${coin}`}>
                          <TableCell align="right">                            
                            {balanceFormatter(tx.amounts[coin], { coin })}
                          </TableCell> 
                          <TableCell align="right">                            
                            <span css={styles.extraPrefix}>
                              {tx.amountsUSD[coin] ? balanceFormatter(tx.amountsUSD[coin]) : ""}
                            </span>
                          </TableCell> 
                        </Fragment>
                      )
                    })
                  }
                  <TableCell align="right">
                    {balanceFormatter(sxPrice, { pos: 4 })}
                  </TableCell>
                  <TableCell align="right">
                    {balanceFormatter(amountUSD)}
                  </TableCell>
                  <TableCell css={styles.hash}>
                    {tx.txHash}
                  </TableCell>

                </TableRow>
              )
            })
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Total</Typography>
            </TableCell>  
            <TableCell />
            <TableCell />
            {
              Object.keys(summary).map(coin => (
                <Fragment key={`total:${coin}`}>
                  <TableCell align="right">
                    {balanceFormatter(summary[coin].amount, { coin })}
                  </TableCell> 
                  <TableCell align="right" key={`total:${coin}`}>
                    {summary[coin].amountUSD ? balanceFormatter(summary[coin].amountUSD) : ""}
                  </TableCell> 
                </Fragment>
                )
              )
            }
            <TableCell />
            <TableCell align="right" >
              <strong>{balanceFormatter(totalCurrentValueUSD)}</strong><br />
              {balanceFormatter(totalAmountUSD)}<br />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer> : null
  )
}

export default StakingRewards;
