import { request } from "graphql-request"
import { Address, TxType } from "../../config/types";
// import { getTokenRate } from "./tokenRate";

export interface IExchangeTxRawBase {
  pairAddress: Address;
  token0Symbol: string;
  token1Symbol: string;
  token0Address: Address;
  token1Address: Address;
  transactionHash: string;
  from: Address;
  to: Address;
  amountUSD: string;
  timestamp: number;
  block: number;
  logIndex: number;
}

export interface IExchangeSwapTxRaw extends IExchangeTxRawBase {
  type: TxType.SWAP;
  amount0In: string;
  amount1In: string;
  amount0Out: string;
  amount1Out: string;
  swaps: {
    token0Symbol: string;
    token1Symbol: string;
    token0Address: Address;
    token1Address: Address;  
    amount0In: string;
    amount1In: string;
    amount0Out: string;
    amount1Out: string;
    from: Address;
    to: Address;
    amountUSD: string;
  }[]
}

export interface IExchangeLpTxRaw extends IExchangeTxRawBase {
  type: TxType.LP_MINT | TxType.LP_BURN; // | TxType.LP_IN | TxType.LP_OUT;
  lpAmount: string;
  amount0: string;
  amount1: string;
}

export const paginationFetcher = async ([endpoint, query, field, variables] : [endpoint: string, query: string, field: string, variables: any]) => {
  const PAGE_SIZE = 1000
  let data: any = []
  for (let i = 0; i < 3; i++) {
    const response = await request(
      endpoint, 
      query, 
      { ...variables, first: PAGE_SIZE, skip: i * PAGE_SIZE }
    )
    const pageData = (response || {})[field] || []
    if (pageData.length > 0) {
      data = data.concat(pageData)
    }
    if (pageData.length < PAGE_SIZE) {
      break
    }
  }
  return { [field]: data }  
}

export const fetcherWithRates = async ([endpoint, query, variables] : [endpoint: string, query: string, variables: any]) => {
  const data = await paginationFetcher([endpoint, query, "swaps", variables])

  console.log("-------- fetcherWithRates data 1", data)

  const swaps = await Promise.all(
    data.swaps.map(async (tx: any) => {
      let rate: number | null = 0;
      
      try {
        // rate = await getTokenRate(Number(tx.blockNumber), tx.destToken)
      } catch (e) {
        console.log(`------ error getting token ${tx.destToken} rate at block ${tx.blockNumber}`)
      }
       
      // console.log("-------- fetcherWithRates rate", rate)

      return { ...tx, destTokenRate: rate }
    })
  )

  console.log("-------- fetcherWithRates data 2", swaps)

  return { swaps }
}

export function mapTxRaw(tx: any, type: TxType): IExchangeSwapTxRaw | IExchangeLpTxRaw | undefined {
  const txBase: IExchangeTxRawBase = {
    transactionHash: tx.transaction.id,
    pairAddress: tx.pair.id,
    token0Symbol: tx.pair.token0.symbol,
    token1Symbol: tx.pair.token1.symbol,
    token0Address: tx.pair.token0.id,
    token1Address: tx.pair.token1.id,
    to: tx.to,
    from: tx.sender,
    amountUSD: tx.amountUSD,
    timestamp: Number(tx.timestamp),
    block: Number(tx.transaction.blockNumber),
    logIndex: Number(tx.logIndex)
  }

  if (type === TxType.LP_MINT || type === TxType.LP_BURN) {
    return ({
      ...txBase,
      type,
      lpAmount: tx.liquidity,
      amount0: tx.amount0,
      amount1: tx.amount1,  
    })
  } else if (type === TxType.SWAP) {
    const swaps = tx.transaction.swaps.map((swap: any) => ({
      token0Symbol: swap.pair.token0.symbol,
      token1Symbol: swap.pair.token1.symbol,
      token0Address: swap.pair.token0.id,
      token1Address: swap.pair.token1.id,
      amount0In: swap.amount0In,
      amount1In: swap.amount1In,
      amount0Out: swap.amount0Out,
      amount1Out: swap.amount1Out,
      from: swap.sender,
      to: swap.to,
      amountUSD: swap.amountUSD,   
      logIndex: Number(swap.logIndex),            
    }))
    return ({
      ...txBase,
      type,
      swaps,
      amount0In: tx.amount0In,
      amount1In: tx.amount1In,
      amount0Out: tx.amount0Out,
      amount1Out: tx.amount1Out,
    })
  }
}

