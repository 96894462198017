import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin, ILiquidityPair, IToken } from "../types";
import USDC from "../abi/USDC.json";
import MinTokenInfo from "../abi/MinTokenInfo.json";
import UniswapV3Pool from "../abi/UniswapV3Pool.json";

const tokens: IToken[] = [
  {
    id: `${AssetId.ETH}:${Chain.ARBITRUM}`,
    assetId: AssetId.ETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.NATIVE,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.ARBITRUM,
    decimals: 18,
  },
  {
    id: `${AssetId.USDC}:${Chain.ARBITRUM}`,
    assetId: AssetId.USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.ARBITRUM,
    address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    decimals: 6,
    abi: USDC
  },
  {
    id: `${AssetId.WETH}:${Chain.ARBITRUM}`,
    assetId: AssetId.WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.ARBITRUM,
    address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SX}:${Chain.ARBITRUM}`,
    assetId: AssetId.SX,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.ARBITRUM,
    address: "0x8CF7e3aa6fAf6Ae180E5eC3f0fb95081C2086eBe",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.ARB}:${Chain.ARBITRUM}`,
    assetId: AssetId.ARB,
    coin: Coin.ARB,
    groupId: AssetGroupId.ARB,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.ARBITRUM,
    address: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    decimals: 18,
    abi: MinTokenInfo
  },

];

// 

const lps: ILiquidityPair[] = [
  // TODO check if it's working
  {
    id: `${AssetId.WETH_SX_SLP}:${Chain.ARBITRUM}`,
    assetId: AssetId.WETH_SX_SLP,
    coin: Coin.WETH_SX_SLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.ARBITRUM,
    address: "0xBC7074733D1dBaEdFB31F031AEb3bFd2fDFd98Ac",
    decimals: 18,
    abi: UniswapV3Pool,
    relatedAssetIds: [AssetId.SX, AssetId.WETH],
    custom: {
      deposits: [] // 149004.21, 20.4999
    },
  },
]



export { tokens, lps }
