import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin, IToken } from "../types"

const tokens: IToken[] = [
  {
    id: `${AssetId.BTC}:${Chain.BITCOIN}`,
    assetId: AssetId.BTC,
    coin: Coin.BTC,
    groupId: AssetGroupId.BTC,
    type: AssetType.NATIVE,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.BITCOIN,
    decimals: 8
  },
]

export { tokens }
