import axios from "axios";
import { Call } from 'ethcall';
import { isArray } from "lodash";
import { AssetId, AssetType, Chain, IAsset, IFarm } from "../config/types";
import { fromWei, isBitcoinAddress, isEthereumAddress } from "../utils";
import { getContractEthcall } from "./contracts";
import { getMulticallProvider } from "./multicall";

export async function getAssetBalancesMulticall(
  assetMeta: IAsset,
  accounts: string[]
): Promise<[string, number][] | undefined> {
  try {
    const { assetId, chainId, address, decimals, abi, type, inactive } = assetMeta;
    if (inactive) {
      return accounts.map(e => [e, 0])
    }
    let calls: Call[] = [];
    let balances: [string, number][];
    let addresses;
    let contract: any;
    if (chainId !== Chain.BITCOIN) {
      /*
      const provider = getStaticProvider(chainId);
      const providerMulticall: any = new ProviderMulticall()
      await providerMulticall.init(provider)
      providerMulticall.patch(chainId)
      */
      const providerMulticall = await getMulticallProvider(chainId)

      // if (chainId === Chain.ARBITRUM) console.log("------- getAssetBalancesMulticall", providerMulticall.multicall2)
      if (address && abi && type !== AssetType.NATIVE) {
        contract = getContractEthcall(assetMeta);
      }
      addresses = accounts.filter((e) => isEthereumAddress(e));
      calls = addresses.map(account => {
        let result;
        if (assetId === AssetId.SX_STAKED) {
          result = contract.getStakedAmounts(account);
        } else if (assetId === AssetId.SX_STAKED_V2) {
          result = contract.getStaker(account);
        } else if (assetId === AssetId.SX_PENDING_V2) {
          result = contract._pendingWithdrawAmounts(account);
        } else if (assetId === AssetId.SX_PENDING) {
          result = contract.getPendingWithdrawAmounts(account)
        } else if (type === AssetType.LP_STAKED) {
          result = contract.userInfo((assetMeta as IFarm).pid, account)
        } else if (type === AssetType.NATIVE) {
          result = providerMulticall.getEthBalance(account)
        } else { // TOKEN OR LP
          result = contract.balanceOf(account)
        }
        return result;
      });
      const data = await providerMulticall.tryAll(calls)
      let i = 0
      balances = addresses.map(address => {
        // console.log("------ data", i, data[i])
        const result: any = data[i++]
        let balanceNum = 0
        if (result._isBigNumber) {
          balanceNum = fromWei(result, decimals)
        } else if (isArray(result)) {
          let index = 0;
          if(!result[0]._isBigNumber && result[1]._isBigNumber) {
            index = 1
          }
          balanceNum = fromWei(result[index], decimals);
        } else if (result.amount) {
          console.log("------- amount", result.amount);
        }
        return [address, type === AssetType.BORROW ? -balanceNum : balanceNum];
      });
    } else {
      addresses = accounts.filter((e) => isBitcoinAddress(e)).join("|");
      const { data } = await axios.get(
        `https://blockchain.info/multiaddr?active=${addresses}&n=1`
      );
      balances = (data.addresses || []).map((e: any) => {
        return ([
          e.address,
          fromWei(e.final_balance, decimals)
        ])
      })
    }
    // if (chainId === Chain.ARBITRUM) {
    //   console.log("------ getAssetBalancesMulticall", assetMeta.assetId, balances); //id, accounts
    // }
    
    return balances;
  } catch (err) {
    console.log("-------- getAssetBalancesMulticall", err, assetMeta);
  }
}
