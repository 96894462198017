/** @jsxImportSource @emotion/react */
import { Box, Checkbox, Chip, Input, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { Accounts } from "../../config/accounts";
import { Address } from "../../config/types";
import { excludedAccountsState, selectedPresetsState } from "../../state/app";
import { getStyles } from "./styles";

function ExcludedAccountSelector() {
  const styles = getStyles()
  const [accounts, setAccounts] = useRecoilState(excludedAccountsState)
  const presets = useRecoilValue(selectedPresetsState)
  const allAccounts = Accounts
  const accountsInCurrentPresets = Accounts.filter(e => e.presets.some(preset => presets.includes(preset))).map(e => e.address)

  const handleChange = (event: SelectChangeEvent<Address[]>) => {
    const { target: { value }} = event;
    setAccounts(value as Address[]);
  };

  return (
    <Select
      multiple
      displayEmpty
      value={accounts}
      onChange={handleChange}
      input={<Input disableUnderline />}
      renderValue={(selected) => (
        <Box css={styles.selector} display="flex">
          <Chip label={`-${selected.length} acct`} size="small" />
        </Box>
      )}
    >
      {allAccounts.map(({ address, label, walletId }) => (
        <MenuItem key={address} value={address}>
          <Checkbox checked={accounts.includes(address)} />
          <Box>
            <Typography variant="body2">{`${walletId} ${label}`}</Typography>
            <Typography 
              className={accountsInCurrentPresets.includes(address) ? "active" : ""} 
              css={styles.account}
            >
              {address}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}

export default ExcludedAccountSelector
