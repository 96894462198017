/** @jsxImportSource @emotion/react */
import startCase from "lodash/startCase"
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { getTableStyles } from "../shared/styles";
import { balanceFormatter, fromWei, getAccountLabel, timestampFormatter } from "../../utils";
import { Fragment } from "react";
import { ITokenMeta } from "../../services/farming";

function FarmingTransactions(params: {
  txs: any,
  tokens: ITokenMeta[]
}) {
  const { txs, tokens } = params
  const styles = getTableStyles()

  console.log("-------- farming transactions", txs);

  //const totalAmountUSD = Object.values(summary || {}).reduce((acc, e) => acc + e.amountUSD, 0)
  //const totalCurrentValueUSD = Object.values(summary || {}).reduce((acc, e) => acc + e.currentValueUSD, 0)


  return (txs ?
    <TableContainer css={styles.container} component={Paper}>
      <Typography css={styles.counter} variant="body2">{txs.length}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">Account</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">Action</Typography>
            </TableCell>
            {/*
            <TableCell>
              <Typography variant="subtitle2">Chain</Typography>
            </TableCell>
            */}
            {
              tokens.map(tk => (
                <TableCell key={tk.address} align="right">
                  <Typography variant="subtitle2">{tk.symbol}</Typography>
                </TableCell>
              ))
            }
            {/*}
            <TableCell>
              <Typography variant="subtitle2" align="right">SX Price</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" align="right">USD Value</Typography>
            </TableCell>
            */}
            <TableCell>
              <Typography variant="subtitle2">Tx hash</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            txs.map((tx: any, i: number) => {
              // const amountUSD = Object.values(tx.amountsUSD).reduce((acc, v) => acc + v, 0)
              // const sxPrice = tx.amountsUSD.SX / tx.amounts.SX
              return (
                <TableRow key={tx.hash}>
                  <TableCell>
                    {timestampFormatter(tx.ts)}
                  </TableCell>
                  <TableCell>
                    {getAccountLabel(tx.account)}
                  </TableCell>
                  <TableCell>
                    {startCase(tx.method)}
                  </TableCell>
                  {/*
                  <TableCell>
                    {Chain[tx.chainId].toLowerCase()}
                  </TableCell>
                  */}
                  {
                    tokens.map(tk => {
                      const transfers = (tx.token_transfers || []).filter((e: any) => e.token.address === tk.address)
                      return (
                        <TableCell key={`${i}:${tk.address}`} align="right">
                          {transfers.map((transfer: any) => {
                            const amount = fromWei(transfer.total.value, transfer.total.decimals) * (transfer.from.hash === tx.account ? -1 : 1)
                            return balanceFormatter(amount)
                          }).join(<br />)}
                        </TableCell> 
                      )
                    })
                  }
                  {/*
                  <TableCell align="right">
                    {balanceFormatter(sxPrice, { pos: 4 })}
                  </TableCell>
                  <TableCell align="right">
                    {balanceFormatter(amountUSD)}
                  </TableCell>
                  */}
                  <TableCell css={styles.hash}>
                    {tx.hash}
                  </TableCell>

                </TableRow>
              )
            })
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Total</Typography>
            </TableCell>  
            <TableCell />
            <TableCell />
            {
              tokens.map((tk) => (
                <Fragment key={`total:${tk.address}`}>
                  <TableCell align="right">
                    {balanceFormatter(-tk.amountOut, { showZero: true })}
                    <br />
                    {balanceFormatter(tk.amountIn, { showZero: true })}
                    <br />
                    {balanceFormatter(tk.amountIn - tk.amountOut, { showZero: true })}
                  </TableCell> 
                </Fragment>
                )
              )
            }
            <TableCell />
            <TableCell align="right" >
              {/*
              <strong>{balanceFormatter(totalCurrentValueUSD)}</strong><br />
              {balanceFormatter(totalAmountUSD)}<br />
              */}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer> : null
  )
}

export default FarmingTransactions;
