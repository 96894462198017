/** @jsxImportSource @emotion/react */

//import { Stack } from "@mui/material"
import { Stack } from "@mui/material"
import { ByCategory } from "./ByCategory"
import { ByGroup } from "./ByGroup"
import { getStyles } from "../app/styles"

function Charts() {
  const styles = getStyles()

  return (
    <Stack css={styles.rightContainer} direction="column" spacing={2}>
      <ByCategory />
      <ByGroup />
    </Stack>
  )
}

export default Charts

/*
sx={{ display: "flex", flexDirection: "column" }}
      <ByCategory />
      <ByGroup />



    <Stack direction="row" spacing={2}>
    </Stack>

*/
