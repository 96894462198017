import { selector } from "recoil";
import { VALUE_BASE } from "../config";
import { Accounts } from "../config/accounts";
import { Assets } from "../config/assets";
import { AssetCategory, AssetType } from "../config/types";
import { balancesWithValuesSelector } from "./balances";
import { liquidityPairSelector } from "./liquidityPairs";
import { rateQuery } from "./rates";

export interface IValuesByCategory {
  [category: string]: number;
}

export const valuesByCategorySelector = selector({
  key: "ValuesByCategory",
  get: ({ get }): IValuesByCategory => {
    const balancesWithValues = get(balancesWithValuesSelector);
    let valuesByCategory: IValuesByCategory = {};

    for (let account of Accounts.map(e => e.address)) {
      for (let asset of Assets) {
        const { id: assetUniqueId, category } = asset;
        if (category !== AssetCategory.LP_TOKEN) {
          if (!valuesByCategory[category]) {
            valuesByCategory[category] = 0;
          }
          const value = (balancesWithValues[assetUniqueId] && balancesWithValues[assetUniqueId][account]
            ? (balancesWithValues[assetUniqueId][account][1] || 0) : 0);
          valuesByCategory[category] += value;  
        }
      }
    }
    const pairs = Assets.filter(e => e.type === AssetType.LP);
    const base = VALUE_BASE;

    for (let pair of pairs ) {
      const { id, chainId } = pair;
      const lp = get(liquidityPairSelector(id));
      if (lp) {
        const { token0, token1, balance0, balance1 } = lp; // , value0, value1
        const meta0 = Assets.find(e => e.assetId === token0 && e.chainId === chainId)!;
        const meta1 = Assets.find(e => e.assetId === token1 && e.chainId === chainId)!;
        const rate0 = get(rateQuery(`${meta0.coin}:${base}`));
        const rate1 = get(rateQuery(`${meta1.coin}:${base}`));
        if (!valuesByCategory[meta0.category]) {
          valuesByCategory[meta0.category] = 0;
        }
        valuesByCategory[meta0.category] += (balance0 * rate0!) || 0; // value0;
        if (!valuesByCategory[meta1.category]) {
          valuesByCategory[meta1.category] = 0;
        }
        valuesByCategory[meta1.category] += (balance1 * rate1!) || 0; // value1;
      }
    }

    return valuesByCategory;
  }
});
