import { atom, selector } from "recoil";
import { rateQuery } from "./rates";
import { Assets } from "../config/assets";
import { Fiat } from "../config/types";

export interface IBalanceState {
  [assetUniqueId: string]: {
    [account: string]: number;
  }
}

export const balanceState = atom<IBalanceState>({
  key: "Balances",
  default: {}
});

interface IBalancesWithValues {
  [assetUniqueId: string]: {
    [account: string]: [number, number];
  }
}

export const balancesWithValuesSelector = selector({
  key: "BalancesWithValues",
  get: ({ get }) => {
    const balancesWithValues: IBalancesWithValues = {};
    const balances = get(balanceState);

    for (let assetUniqueId in balances) {
      const { coin } = Assets.find(e => e.id === assetUniqueId)!;
      const rate = get(rateQuery(`${coin}:${Fiat.USD}`))!;
      balancesWithValues[assetUniqueId] = {};
      for (let account in balances[assetUniqueId]) {
        balancesWithValues[assetUniqueId][account] = [
          balances[assetUniqueId][account], 
          balances[assetUniqueId][account] * rate
        ]
      }
    }
    return balancesWithValues;
  }
});
