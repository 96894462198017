import { selector } from "recoil";
import { VALUE_BASE } from "../config";
import { Accounts } from "../config/accounts";
import { Assets } from "../config/assets";
import { AssetCategory, AssetGroupId, AssetType, Fiat, IAsset } from "../config/types";
import { balanceState, balancesWithValuesSelector } from "./balances";
import { liquidityPairSelector } from "./liquidityPairs";
import { rateQuery } from "./rates";

// Currency = Coin | Fiat
interface IBalancesByCurrency {
  [currency: string]: number; 
}

export interface IBalancesByGroup {
  [group: string]: IBalancesByCurrency;
}

interface IAssetsByGroup {
  [group: string]: IAsset[];
}

const assetsByGroup = Assets.reduce((acc: IAssetsByGroup, assetMeta) => {
  if (!acc[assetMeta.groupId]) {
    acc[assetMeta.groupId] = [];
  }
  acc[assetMeta.groupId].push(assetMeta);
  return acc;
}, {});

const groups = Object.values(AssetGroupId);
const accounts = Accounts.map(e => e.address);

export const balancesByGroupSelector = selector({
  key: "BalancesByGroup",
  get: ({ get }) => {
    const balances = get(balanceState);
    let balancesByGroup: IBalancesByGroup = {};

    for (let group of groups) {
      const assets = (assetsByGroup[group] || []).filter(e => e.type !== AssetType.DEPRECATED);
      let balancesByCurrency: IBalancesByCurrency = {
        [Fiat.USD]: 0 
      };
      for (let account of accounts) {
        for (let asset of assets) {
          const { id: assetUniqueId, coin } = asset;
          if (balancesByCurrency[coin] === undefined) {
            balancesByCurrency[coin] = 0;
          }
          const balance = (balances[assetUniqueId] ? balances[assetUniqueId][account] || 0 : 0);
          balancesByCurrency[coin] += balance;
          const rate = get(rateQuery(`${coin}:${Fiat.USD}`)) || 0;
          balancesByCurrency[Fiat.USD] += balance * rate;
          // if (assetUniqueId === "A_WETH:137" && Object.keys(balances).length > 0 && balance !== 0) debugger //  && account === "0x47378BFb91FcdD9a377D44C13f7Cff185129b6d5"
        }
      }
      balancesByGroup[group] = balancesByCurrency;
    }
    return balancesByGroup;
  }
});

interface ISummaryByGroup {
  [group: string]: [number, number];
}

export const summaryByGroupSelector = selector({
  key: "SummaryByGroup",
  get: ({ get }) => {
    const balancesWithValues = get(balancesWithValuesSelector);
    let summaryByGroup: ISummaryByGroup = {};
    for (let group of groups) {
      const assets = (assetsByGroup[group] || []).filter(e => e.type !== AssetType.DEPRECATED);
      let groupBalance = 0;
      let groupValue = 0;
      for (let account of accounts) {
        for (let asset of assets) {
          if (asset.category !== AssetCategory.LP_TOKEN) {
            const values = balancesWithValues[asset.id] ? balancesWithValues[asset.id][account] : undefined;
            if (values) {
              groupBalance += values[0] || 0;
              groupValue += values[1] || 0;    
            }
          }
        }
      }
      if (groupBalance !== 0) {
        summaryByGroup[group] = [groupBalance, groupValue];
      }
    }
    
    const pairs = Assets.filter(e => e.type === AssetType.LP);
    const base = VALUE_BASE;

    for (let pair of pairs ) {
      const { id, chainId } = pair;
      const lp = get(liquidityPairSelector(id));
      if (lp) {
        const { token0, token1, balance0, balance1 } = lp; // , value0, value1
        const meta0 = Assets.find(e => e.assetId === token0 && e.chainId === chainId)!;
        const meta1 = Assets.find(e => e.assetId === token1 && e.chainId === chainId)!;
        const rate0 = get(rateQuery(`${meta0.coin}:${base}`));
        const rate1 = get(rateQuery(`${meta1.coin}:${base}`));
        if (!summaryByGroup[meta0.groupId]) {
          summaryByGroup[meta0.groupId] = [0, 0];
        }
        summaryByGroup[meta0.groupId][0] += balance0 || 0;
        summaryByGroup[meta0.groupId][1] += (balance0 * rate0!) || 0; // value0;

        if (!summaryByGroup[meta1.groupId]) {
          summaryByGroup[meta1.groupId] = [0, 0];
        }
        summaryByGroup[meta1.groupId][0] += balance1 || 0;
        summaryByGroup[meta1.groupId][1] += (balance1 * rate1!) || 0; // value1;
      }
    }

    return summaryByGroup;
  }
});


/*
const assetsByCurrency = Assets.reduce((acc: IAssetsByGroup, assetMeta) => {
  const currency = [AssetId.SX_STAKED, AssetId.SX_PENDING].includes(assetMeta.assetId)
  ? Curre 

  if (!acc[assetMeta.currency]) {
    acc[assetMeta.currency] = [];
  }
  acc[assetMeta.currency].push(assetMeta);
  return acc;
}, {});

interface ISummaryByCurrency {
  [currency: string]: [number, number];
}

export const summaryByCurrencySelector = selector({
  key: "SummaryByCurrency",
  get: ({ get }) => {
    const balancesWithValues = get(balancesWithValuesSelector);
    let summaryByCurrency: ISummaryByCurrency = {};

    for (let currency in assetsByCurrency) {
      for (let asset of assetsByCurrency[currency]) {
        const { id: assetUniqueId, category } = asset;
        if (category !== AssetCategory.LP_TOKEN) {
          if (!summaryByCurrency[currency]) {
            summaryByCurrency[currency] = [0, 0];
          }  
          for (let account of accounts) {
            const balance = (
              balancesWithValues[assetUniqueId] && balancesWithValues[assetUniqueId][account] 
              ? balancesWithValues[assetUniqueId][account][0] || 0 
              : 0
            );  
            const value = (
              balancesWithValues[assetUniqueId] && balancesWithValues[assetUniqueId][account] 
              ? balancesWithValues[assetUniqueId][account][1] || 0 
              : 0
            );  
            summaryByCurrency[currency][0] += balance;
            summaryByCurrency[currency][1] += value;
          }  
        }
      }
    }
    return summaryByCurrency;
  }
});

*/
