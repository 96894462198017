import { atomFamily, selectorFamily } from "recoil";
import { Farms, LPs } from "../config/assets";
import { AssetId, Chain, Coin } from "../config/types";
import { getLiquidityPair } from "../services/liquidityPairs";
import { balanceState } from "./balances";

export interface ILiquidityPairAtom {
  totalSupply: number;
  reserve0: number;
  reserve1: number; 
}

export interface ILiquidityPairSelector extends ILiquidityPairAtom {
  chainId: Chain;
  coin: Coin;
  myBalance: number;
  myShare: number;
  token0: AssetId;
  token1: AssetId;
  balance0: number;
  balance1: number;
  deposits?: [number, number];
}

export const liquidityPairState = atomFamily<ILiquidityPairAtom | undefined, string>({
  key: "LiquidityPairsState",
  default: async (assetUniqueId: string) => (
    getLiquidityPair(assetUniqueId)
  )
});

export const liquidityPairSelector = selectorFamily<ILiquidityPairSelector | undefined, string>({
  key: "LiquidityPairSelector",
  get: assetUniqueId => async ({ get }) => {
    // console.log("------ liquidityPairSelector", assetUniqueId);
    const meta = LPs.find(e => e.id === assetUniqueId)!;
    const lp = get(liquidityPairState(assetUniqueId));

    const [token0, token1] = meta.relatedAssetIds!;

    const balances = get(balanceState);
    const relatedAssets1 = LPs.filter(e => e.relatedAssetIds?.includes(meta.assetId)) || [];
    const relatedAssets2 = Farms.filter(e => e.relatedAssetIds?.includes(meta.assetId)) || [];

    // console.log("------ liquidityPairSelector values", assetUniqueId, lp, rate0, rate1, balances);

    if (!lp) return undefined; // || rate0 === undefined || rate1 === undefined

    const { reserve0, reserve1, totalSupply } = lp;

    let myBalance = 0;

    for (let asset of [meta, ...relatedAssets1, ...relatedAssets2]) {
      const assetBalances = balances[asset.id];
      for (let address in assetBalances) {
        myBalance += assetBalances[address] || 0;
      }  
    }
    const myShare = myBalance / totalSupply;
    const balance0 = reserve0 * myShare; 
    const balance1 = reserve1 * myShare;

    const { deposits } = meta.custom || {};

    const result: ILiquidityPairSelector = {
      chainId: meta.chainId,
      coin: meta.coin,
      totalSupply,
      reserve0,
      reserve1, 
      myBalance,
      myShare,
      token0,
      token1,
      balance0,
      balance1,
      deposits,
    };

    // console.log("------ liquidityPairSelector result", result);

    return result;
  }
});


    //const meta0 = Assets.find(e => e.assetId === token0 && e.chainId === meta.chainId)!;
    //const meta1 = Assets.find(e => e.assetId === token1 && e.chainId === meta.chainId)!;

    //const rate = get(rateQuery(`${}:${}`)) 
    //const rate0 = get(rateQuery(`${meta0.coin}:${Fiat.USD}`));
    //const rate1 = get(rateQuery(`${meta1.coin}:${Fiat.USD}`));

    //const rate = (reserve0 * rate0 + reserve1 * rate1) / totalSupply;

    //const totalValue = totalSupply * rate;
    //const myValue = myBalance * rate; 
    //const value0 = balance0 * rate0; 
    //const value1 = balance1 * rate1;
